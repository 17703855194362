import ITask from "./ITask";
import { v4 as uuid } from 'uuid';

interface Props{
    tasks: Array<ITask>
}

const levelp = [
    "charu",
    "agni",
    "murthy",
    "sravan",
    "venky",
    "sai chand"
]

const Assignments = ({tasks}: Props) => {
    return(
        <div>
            <div className="flex flex-col border-2 border-rose-500 items-center justify-center">
                <div className=" p-2 divide-y-4 text-center">
                    {tasks.map((each,i) => (
                            <div key={uuid()} className="flex flex-row divide-x-4 items-center justify-center">
                                <div className="basis-1/6  p-2 m-2">
                                    {i}
                                </div>
                                <div className="basis-1/6">
                                    {each.priority}
                                </div>
                                <div className="basis-3/6 p-2 overflow-hidden">
                                    {each.headline}
                                </div>
                                <div className="p-3 basis-1/6">
                                    Assigned To: {each.assingedto}
                                </div>
                                <div className="p-3 basis-1/6">
                                    <select className="border-2 border-rose-500 rounded-lg" 
                                           >
                                        <option>select</option>
                                        {levelp.map((item) => (
                                                <option key={item} className="p-2">
                                                    {item}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                                <div className="p-3 basis-1/6">
                                    <button className="rounded-lg bg-lime-500 p-2" 
                                                >Submit</button>
                                </div>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    )
}

export default Assignments ;