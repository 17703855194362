import PointsTable from '../components/PointsTable'
import NewsComponent from '../components/NewsComponent'
import MetaData from './MetaData'


const leagues = [
    "IPL"
]
const MobileSports = () => {
    return(
        <div className='td:hidden'>
            
            <MetaData title="SHOWbuoy | Latest Cricket news" img="" type="page"
                        headline="Find all the latest news and articles on Criket and IPL" 
                        tags="sports, cricket, IPL, Mumbai Indians, Punjab Kings,
                            Delhi Capitals, Luknow Super Gaints, Kolkata knight riders,
                            Sunrisers Hyderabad, Royal Challengers Bangalore, Chennai Super Kings"
                            publishedtime = "Some time" 
            />
               
            <div className="hidden flex flex-row text-center text-sm mb-2">
                <div className='basis-1/2 space-y-1'>
                    This would be a dropdown
                    {
                        leagues.map((item) => (
                            <div key={item} className="text-center text-white bg-amber-700 rounded-xl">
                                <button>{item}</button>
                            </div>
                        ))
                    }
                </div>
                <div className='basis-1/2'>
                    <div className='basis-1/2 space-y-1'>
                        This would be a dropdown
                        <div className='text-center text-white bg-amber-700 rounded-xl'>
                            <button>
                                CSK
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="max-h-96 space-y-2 bg-gray-700 rounded-xl 
                            shadow-xl text-white overflow-y-auto d:scrollbar-hide">
                <div className="p-3 space-y-4">
                    <PointsTable />
                </div>
            </div>
            <div className="">
                {
                    <NewsComponent type="sports"/>
                }
            </div>
        </div>
    )
}

export default MobileSports;