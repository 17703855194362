import React, { useState } from "react";
import { ILogin, IUser } from "../entities/IUser";
import {signUp, signIn} from '../client/apiCall';
import { getMovieById } from "../client/getData";
import {loggedInState, isAdmin} from "../state/userState"
import { useRecoilState } from "recoil";

const Login = (props:any) => {

    const [loggedIn, setLoggedIn] = useRecoilState(loggedInState);
    
    const [isAdminTrue, setAdminTrue] = useRecoilState(isAdmin);

    const [loginValues, setLoginvalues] = useState<ILogin>({
                                            email:'',
                                            password:''
                                            });
     
    const [signUpValues, setSignUpValues] = useState<IUser>({
                                                email:'',
                                                firstname:'',
                                                lastname:'',
                                                password:''
                                            })

    const [signupClicked, setSignupClicked] = useState(false);

    const updateLoginDetails= (event: React.FormEvent<HTMLInputElement>): void => {
        setLoginvalues({
            ...loginValues,
            [event.currentTarget.name]: event.currentTarget.value
        })
    }

    const updateSignUpDetails= (event: React.FormEvent<HTMLInputElement>): void => {
        setSignUpValues({
            ...signUpValues,
            [event.currentTarget.name]: event.currentTarget.value
        })
    }

    const handleLoginSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const res = await signIn(loginValues);
        if(res.success){
            setLoggedIn(true);
            localStorage.setItem('token', res["jwt"]);
            if(res.role === 'admin')
            {
                setAdminTrue(true);
                localStorage.setItem('admin', 'admin');
            }
        }  
        // This sets global state of recoil loggen In true
        // This will close the pop up window
        props.updateLoginClicked();
    }

    const handleSignUpSubmit= async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // This will close the pop up window
        await signUp(signUpValues);
        props.updateLoginClicked();
        console.log("signUpValues : ", signUpValues);
    }

    return(
        <div className="Login">
              <div className="font-serif text-gray-700">
                <div className="min-w-screen h-screen animated faster fixed  
                                    left-0 top-0 flex justify-center items-center inset-0 z-50 
                                    outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
                    <div className="absolute bg-black opacity-40 inset-0 z-0"></div>
                    <div className="w-full max-w-xl p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-cyan-800 ">
                    {/**
                     * Login Form
                     */}
                     <div className="text-center pt-5 flex-auto justify-center text-amber-300 font-serif">
                            <button className="text-3xl" onClick={props.updateLoginClicked}> &#x2715; </button>
                    </div>
                        {
                            (!signupClicked) && 
                                <form onSubmit={e => handleLoginSubmit(e)} className="text-center p-5 flex-auto justify-center text-amber-300 font-serif">
                                    <div className="mb-2 p-2">
                                        <input className="h-10 w-80 rounded-md mr-3 p-2 text-black" name="email" type="email" 
                                        onChange={e => updateLoginDetails(e)} placeholder="email@xyz.com"/>
                                    </div>

                                    <div className="mb-2 p-2">
                                        <input className="h-10 w-80 rounded-md mr-3 p-2 text-black" 
                                            name="password" type="password" onChange={e => updateLoginDetails(e)} placeholder="Password"/>
                                    </div>

                                    <div className="grid grid-cols-2 m-6 gap-20">
                                        <button className="border-2 border-rose-500 rounded p-1" 
                                        onClick={()=>setSignupClicked(true)} >Create Account</button>

                                        <button className="border-2 border-rose-500 rounded p-1" 
                                                type="submit" >Login</button>
                                    </div>
                                </form>
                        }
                        {
                            (signupClicked) && 
                                <form onSubmit={e => handleSignUpSubmit(e)} 
                                        className="text-center p-5 flex-auto justify-center text-amber-300 font-serif">
                                    <div className="mb-2 p-2">
                                            <input className="h-10 w-80 rounded-md mr-3 p-2 text-black" 
                                                name="firstname" type="text" onChange={e => updateSignUpDetails(e)} placeholder="First Name"/>
                                    </div>
                                    <div className="mb-2 p-2">
                                            <input className="h-10 w-80 rounded-md mr-3 p-2 text-black"    
                                                name="lastname" type="text" onChange={e => updateSignUpDetails(e)} placeholder="Last Name"/>
                                    </div>
                                    
                                    <div className="mb-2 p-2">
                                        <input className="h-10 w-80 rounded-md mr-3 p-2 text-black" name="email" type="email" 
                                        onChange={e => updateSignUpDetails(e)} placeholder="email@xyz.com"/>
                                    </div>

                                    <div className="mb-2 p-2">
                                        <input className="h-10 w-80 rounded-md mr-3 p-2 text-black" 
                                            name="password" type="password" onChange={e => updateSignUpDetails(e)} placeholder="Password"/>
                                    </div>
                                    <div className="mb-6 p-2">
                                            <input className="h-10 w-80 rounded-md mr-3 p-2 text-black" 
                                                type="password" placeholder="Confirm Password"/>
                                    </div>

                                    <div className="grid grid-cols-2 m-6 gap-20">
                                        <button className="border-2 border-rose-500 rounded p-1" 
                                        onClick={()=>setSignupClicked(false)} >Login</button>

                                        <button className="border-2 border-rose-500 rounded p-1" 
                                              type="submit" >Sign Up</button>
                                    </div>
                                </form>
                            }
                    </div>
                </div>
            </div> 
                        
        </div>
        
    )
}

export default Login;