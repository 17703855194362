import ITask from "./ITask";
import {workflows} from './Constants';
import { v4 as uuid } from 'uuid';

interface Props{
    assignedto:string,
    status:string,
    tasks:Array<ITask>
}

const EmployeeTasks = ({assignedto, status, tasks}:Props) => {
    return(
        <div className="flex flex-row border-2 border-rose-500 my-2 divide-x-4">
            <div className="basis-1/6 flex items-center justify-center">
                {assignedto}
            </div>

            <div className="basis-2/6 p-2 divide-y-4">
                {tasks.map((each,i) => {
                    if(each.status === status || status === "All"){
                        return(
                            <div key={uuid()} className="h-16 flex items-center justify-center">
                                {each.headline}
                            </div>
                        )
                    }else{
                        return null;
                    }
                })}
            </div>
            <div className="basis-1/6 p-2 divide-y-4">
                {tasks.map((each,i) => {
                        if(each.status === status || status === "All"){
                            return(
                                <div key={uuid()} className="h-16 flex items-center justify-center">
                                    {each.status}
                                </div>
                            )
                        }else{
                            return null;
                        }
                    }
                )}
            </div>
            <div className="basis-1/6 p-2 divide-y-4">
                {tasks.map((each,i) => {
                            if(each.status === status || status === "All"){
                                return(
                                    <div key={uuid()} className="h-16 flex items-center justify-center">
                                        {each.deadline.toDateString()}
                                    </div>
                                )
                            }else{
                                return null;
                            }
                        }
                    )}
            </div>
            <div className="basis-1/6 p-1 divide-y-4">
                {tasks.map((each,i) => {
                        if(each.status === status || status === "All"){
                            return(
                                <div key={uuid()}>
                                    <select className="p-1 border-2 border-rose-500 rounded-lg my-4" 
                                            value={each.status} onChange={() => {}}>
                                        {workflows.map((item) => (
                                            <option key={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                    <button className="rounded bg-lime-500 p-1 ml-2">Save</button>
                                </div>
                            )
                        }else{
                            return null;
                        }
                    }
                )}
            </div>
        </div>
    )
}

export default EmployeeTasks;