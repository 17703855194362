import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import IArtist from "../entities/IArtist";
import {getArtistbyId} from "../client/getData";
import {Helmet} from "react-helmet";
import MetaData from './MetaData'

const ArtistProfile = () => {
    const {id} = useParams();
    // console.log(id)
    const [artist, setArtist] = useState<IArtist>();

    const setReviewAsync = async() => {
        const artistData = await getArtistbyId(id!);
        setArtist(artistData!);
    }

    // console.log("artist : ", artist);

    let img = "https://surfserver.in" + artist?.imgpath; 
    
    // console.log("img : ", img);
    // const formatData = (artist: IArtist) => {
    //     let formattedData = {};
    // }
    
    useEffect(() => {
        setReviewAsync();
    },[]);// Ignore warning
    return(
        <div className="bg-slate-200">
            {
                (artist) && 
                <MetaData title={`SHOWbuoy | ${artist?.name}`} img={img} type="page"
                            headline={`Life Aspects of ${artist?.name}`} 
                            tags="movies, telugu movies, hindi movies, tamil movies, kannada movies, 
                                    malayalam movies, top movies, Netflix, Prime Video, Sun Nxt, Aha Video,
                                    Top Indian movies in Netflix, Disney + Hotstar, Youtube, Jio Movies, Zee 5,
                                    Top Telugu Movies, Top Hindi Movies, Top Tamil Movies, Top Malayalam Movies"
                                publishedtime = "Some time" 
                />
            }
            <Helmet>
                <meta name="og:image" content={img} />
                <meta name="description" content={`Life Aspects of ${artist?.name}`} />
            </Helmet>
            {
                (artist === undefined) && 
                    <div className="animate-pulse text-center">
                        Loading ...
                    </div>
            }
            {
                (artist) && 
                <div className="flex flex-row m:flex-col mx-10">
                    <div className="td:basis-1/5 bg-white m-3">
                        <div className="flex justify-center p-2">
                            <img className="h-64 w-48 float-none rounded-xl" src={img} alt="Not Found" />
                        </div>
                        <div className="flex justify-center font-medium">
                            {artist.name}
                        </div>
                        <hr></hr>
                        <div className="flex justify-center text-sm m-1 p-1">
                            {artist.info}
                        </div>
                    </div>
                    <div className="td:basis-2/5 m-3 p-3 ">
                        <div className="bg-white rounded-xl">
                            <div className="flex justify-center font-bold">
                                About
                            </div>
                            <div className="flex flex-col text-left gap-y-1">

                            </div>
                        </div>
                        <div className="rounded-xl bg-white mt-2" >
                            <div className="flex justify-center font-bold">
                                Movies
                            </div>
                        </div>
                        
                    </div>

                    <div className="m:hidden td:basis-2/5 flex flex-col mx-3 gap-y-2 gap">
                        <div className="text-center text-lg font-bold text-orange-900">
                            Relavant Articles
                        </div>
                        <div className="flex h-32 justify-center bg-slate-800 text-white rounded-xl">
                            News Articles
                        </div>
                        <div className="flex h-32 justify-center bg-slate-800 text-white rounded-xl">
                            News Articles
                        </div>
                    </div>
                </div>
            }
            <div className="td:hidden basis-2/5 flex flex-col mx-3 gap-y-2 gap">
                <div className="text-center text-lg font-bold text-orange-900">
                    Relavant Articles
                </div>
                <div className="flex h-32 justify-center bg-slate-800 text-white rounded-xl">
                    News Articles
                </div>
                <div className="flex h-32 justify-center bg-slate-800 text-white rounded-xl">
                    News Articles
                </div>
            </div>
        </div>
    )
}

export default ArtistProfile;