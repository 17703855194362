const PageUnderDevelopment = () => {
    return(
        <div className="PageUnderDevelopment">
            <div className="text-5xl font-extrabold text-center m-10">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
                    Page Under Development
                </span>
            </div>
        </div>
    )
}

export default PageUnderDevelopment;