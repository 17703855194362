import { useRecoilState } from "recoil";
import {artists} from "../state/recoilBootstrapState";
import IArtist from '../entities/IArtist'
import { Link } from "react-router-dom";

interface Props{
    artist: IArtist
}

const ArtistsCard = ({artist}: Props) => {
    let imgUrl = "https://surfserver.in"+artist.imgpath;
    let url = "profile/" + artist.name+"/"+artist.id;
    return(
        <div className="ArtistsCard cursor-pointer text-sm">
            <Link to={url}>
                <div className="grid grid-flow-row h-full border-2 border-rose-500 rounded-xl
                                            shadow-lg shadow-neutral-500 bg-yellow-700 w-64 max-w-sm 
                                            place-self-center">
                    <div className="place-self-center p-2">
                        <img className="object-center h-48 rounded-xl" src={imgUrl} alt="Sunset in the mountains" />
                    </div>
                    <div className="border-t-2 border-black p-2 text-slate-300">
                        <div>
                            Name : <span className="italic text-slate-800">{artist.name}</span>
                        </div>
                        <div>
                            Info: <span className="italic text-slate-800">{artist.info.slice(0,100)}</span> ...
                        </div>
                        <div>
                            Age: <span className="italic text-slate-800">No Data</span>
                        </div>
                        <div>
                            Net Worth: <span className="italic text-slate-800">{artist.networth}</span>
                        </div>
                        <div>
                            Highest Gross: <span className="italic text-slate-800">No Data</span>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default ArtistsCard;