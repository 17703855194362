import { Route, Routes } from "react-router-dom";
import Kystar from "./pages/Kystar";
import Home from "./pages/Home";
import MobileHome from "./pages/MobileHome";
import MobileMovies from "./pages/MobileMovies";
import Movies from "./pages/Movies";
import AddNewRoute from "./routers/AddNewRoute";
import ArtistProfile from "./pages/ArtistProfile";
import PageUnderDevelopment from "./pages/PageUnderDevelopment";
import Footer from "./components/Footer";
import { useRecoilState } from "recoil";
import {useEffect} from 'react';
import {getReviewsData, getAllArticles, getSportsListbyGroup} from "./client/getData";
import {reviewsState, articlesState, pointsState} from "./state/recoilBootstrapState";
import { isAdmin, loggedInState } from "./state/userState";
import MovieReview from "./pages/MovieReview";
import Sports from "./pages/Sports";
import MobileSports from "./pages/MobileSports";
import Edit from "./routers/Edit";
import News from "./pages/News";
import MobileNews from './pages/MobileNewsPage'
import Workflow from "./workflow/Workflow";
import Movie from "./pages/Movie";
import MobileMovie from "./pages/MobileMovie";
import { FaFacebookSquare } from 'react-icons/fa';
import {FaYoutube} from 'react-icons/fa';
import {AiFillInstagram} from 'react-icons/ai';
import {FaTwitter} from 'react-icons/fa';

const Main = () => {
    
    const [reviewsState_, setReviewsState] = useRecoilState(reviewsState);
    const [articlesState_, setArticlesState] = useRecoilState(articlesState);
    const [pointsState_, setPointsState] = useRecoilState(pointsState);

    const setReviewsState_ = async () => {
        const data = await getReviewsData();
        setReviewsState(data!);
    }

    const setArticlesState_ = async () => {
        const data = await getAllArticles();
        setArticlesState(data!);
    }

    const setPointsDtate_ = async () => {
        const data = await getSportsListbyGroup('ipl');
        setPointsState(data!);
    }

    const [isAdminTrue, setAdminTrue] = useRecoilState(isAdmin);
    const [loggedIn, setLoggedIn] = useRecoilState(loggedInState);
    
    if(localStorage.getItem('admin') === 'admin')
    {
        setAdminTrue(true);
        setLoggedIn(true);
    }

    useEffect(() => {
        setReviewsState_();
        setArticlesState_();
        setPointsDtate_();
    },[]);

    return(
        <div className="Main">
            <div className="grid grid-rows-1 mt-1">
                <div className="bg-slate-800 text-gray-300 text-center py-2 lg:px-4 rounded-lg">
                    <div className="p-2 bg-slate-700 items-center text-indigo-100 leading-none 
                            lg:rounded-full flex lg:inline-flex" role="alert">
                        <div className="basis-1/4 ">
                            <span className="rounded-full bg-orange-500 uppercase px-2 py-1 text-xs font-bold mr-3 text-left">New</span>
                        </div>
                        <span className="basis-2/4 font-semibold mr-2 text-center flex-auto">(This weeks OTT Movies)</span>
                        <div className="basis-1/4 flex flex-row p-1 space-x-6">
                            Follow Us &nbsp; &nbsp;
                            <div className='flex justify-center ml-5'>
                                <a href="https://www.facebook.com/showbuoyofficial" rel="noreferrer" target="_blank"> 
                                <FaFacebookSquare /> </a>
                            </div>
                            <div className='flex justify-center'>
                                <a href="https://www.youtube.com/channel/UCI1anQtKiL8l-d0UgAFaChw" rel="noreferrer" target="_blank"> 
                                <FaYoutube className=''/> </a>
                            </div>
                            <div className='flex justify-center'>
                                <a href="https://www.instagram.com/showbuoy_official/" rel="noreferrer" target="_blank"> 
                                <AiFillInstagram /> </a>
                            </div>
                            {/* <div className='flex justify-center'>
                                <a href="http://www.twitter.com" rel="noreferrer" target="_blank"> 
                                <FaTwitter /> </a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="m:hidden ">
                <Routes>
                    <Route path="/addnew" element = {<AddNewRoute />} />
                    <Route path="/edit" element = {<Edit />} />
                    <Route path="/" element = {<Home />} />
                    <Route path="/home" element = {<Home />} />
                    <Route path="/movies" element = {<Movies />} />
                    <Route path="/sports" element = {<Sports />} />
                    <Route path="/artist" element = {<Kystar />} />
                    <Route path="/art" element = {<PageUnderDevelopment />} />
                    <Route path="/chance" element = {<PageUnderDevelopment />} />
                    <Route path="/review/:name/:id" element = {<MovieReview />} />
                    <Route path="/artist/profile/:name/:id" element = {<ArtistProfile />} />
                    <Route path="/news/:headline/:id" element = {<News />} />
                    <Route path="/movie/:id" element = {<Movie />} />
                    <Route path="/workflow" element = {<Workflow />} />
                </Routes>
            </div>
            <div className="td:hidden">
                <Routes>
                    <Route path="/addnew" element = {<AddNewRoute />} />
                    <Route path="/edit" element = {<Edit />} />
                    <Route path="/" element = {<MobileHome />} />
                    <Route path="/home" element = {<MobileHome />} />
                    <Route path="/movies" element = {<MobileMovies />} />
                    <Route path="/sports" element = {<MobileSports />} />
                    <Route path="/artist" element = {<Kystar />} />
                    <Route path="/art" element = {<PageUnderDevelopment />} />
                    <Route path="/chance" element = {<PageUnderDevelopment />} />
                    <Route path="/review/:name/:id" element = {<MovieReview />} />
                    <Route path="/artist/profile/:name/:id" element = {<ArtistProfile />} />
                    <Route path="/news/:headline/:id" element = {<MobileNews />} />
                    <Route path="/movie/:id" element = {<MobileMovie />} />
                    <Route path="/workflow" element = {<Workflow />} />
                </Routes>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
}

export default Main;