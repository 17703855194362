import React, { useState } from "react";
import {uploadFile} from '../client/apiCall';
import { IMovieList } from "../entities/IList";
import { useRecoilState } from 'recoil';
import IMovie from "../entities/IMovie";
import { v4 as uuid } from 'uuid';
import {isNullOrEmpty} from "../validations/validate";
import {uploadMovie} from '../client/apiCall';
import SuccessCard from '../cards/SuccessCard';
import { imagePath } from '../entities/constant';
import {TiTickOutline} from 'react-icons/ti';
import { searchNames } from "../helpers/search";
import InfoCard from "../cards/InfoCard";
import DropDown from "./DropDown";
import {movies, artists} from "../state/recoilBootstrapState";
import { languages,platform,genres } from "../entities/constant";

const AddMovie = () => {
    // Get the artists and movies data already fetched when website launched
    const [moviesList, setMovieList] = useRecoilState(movies);
    const [artistList, setArtistList] = useRecoilState(artists);  

    const [uploadClicked, setUploadClicked] = useState(false);
    const [movieUploadedSuccessfully, SetMovieUploadedSuccessfully] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadImage, setUploadImage] = useState<File>();

    const [movieDetails, setMovieDetails] = useState<IMovie>({
                                                        id:uuid(),
                                                        name:'',
                                                        language:'',
                                                        actors: '',
                                                        actresses: '',
                                                        cast: '',
                                                        directors: '',
                                                        writers: '',
                                                        producers: '',
                                                        productionhouses: '',
                                                        banners: '',
                                                        releasedate: new Date(0),
                                                        info:'',
                                                        img:'',
                                                        genres:'',
                                                        platform:'',
                                                        ottreleasedate: new Date(0),
                                                        tags:''
                                                    });
        
    const updateMovieDetails = (event: React.FormEvent<HTMLInputElement>): void => {
        setMovieDetails({
            ...movieDetails,
            [event.currentTarget.name]: event.currentTarget.value
        })
    }

    const onSelectfromDropDown = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();

        setMovieDetails({
            ...movieDetails,
            [event.currentTarget.name]: event.currentTarget.value
        })
    }


    const setImgPath = (setImgPath) => {
        const val = imagePath + "/" +movieDetails.id + "." + setImgPath;
        setMovieDetails({
            ...movieDetails,
            "img": val
        })
    }
    
    const handleImageFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (!event.currentTarget.files) return;
        const file: FileList = event.currentTarget.files;
        setUploadImage(file[0]);
        const size = file[0].name.split('.'); // returns png/jpeg
        const format = file[0].name.split('.')[size.length-1];
        setImgPath(format);
    }

    const onClickUpload = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if(uploadImage === undefined)
        {
            alert("Choose a file to upload")
        }else{
            const formData = new FormData();
            formData.append("name", movieDetails.id);
            formData.append("file",uploadImage!);
            let res = await uploadFile(formData);
            if(res) setUploadSuccess(true)
            setUploadClicked(true);
        }
    }

    const [showMovieSearchDropDown, setShowMovieSearchDropDown] = useState(false);
    const [showArtistSearchDropDown, setShowArtistSearchDropDown] = useState(false);
    const [searchList, setSearchList] = useState<Array<IMovieList>>();

    const handleShowMovieSearchDropDown = (val:string) => {
        if(val.length > 0) setShowMovieSearchDropDown(true)
        else setShowMovieSearchDropDown(false)
    }

    const handleSearchList = async (val:string, data: any) => {
        let filteredData = [];

        filteredData = await searchNames(val, data);

        setSearchList(filteredData);
        if(filteredData?.length === 0 ) {
            setShowMovieSearchDropDown(false);
            setShowArtistSearchDropDown(false);
            setShowActressSearchDropDown(false);
        }
    }

    const onMovieSelectChange = (data: any) => {
        setMovieDetails({
            ...movieDetails,
            name: data.name
        })
        setShowMovieSearchDropDown(false);
    }

    const onSearchName = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const val = event.currentTarget.value;
        setMovieDetails({
            ...movieDetails,
            [event.currentTarget.name]: event.currentTarget.value
        })
        handleShowMovieSearchDropDown(val);
        handleSearchList(val, moviesList);
    }

    const onSearchActorName = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const val = event.currentTarget.value;
        setMovieDetails({
            ...movieDetails,
            [event.currentTarget.name]: event.currentTarget.value
        })
        handleArtistSearchDropDown(val);
        handleSearchList(val, artistList);
    }

    const handleArtistSearchDropDown = (val:string) => {
        if(val.length > 0) setShowArtistSearchDropDown(true)
        else setShowArtistSearchDropDown(false)
    }

    const onArtistSelectChange = (data:any) => {
        setMovieDetails({
            ...movieDetails,
            actors: data.name
        })
        setShowArtistSearchDropDown(false)
    }

    // Select Actress

    const [showActressSearchDropDown, setShowActressSearchDropDown] = useState(false);

    const onSearchActressName = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const val = event.currentTarget.value;
        setMovieDetails({
            ...movieDetails,
            [event.currentTarget.name]: event.currentTarget.value
        })
        handleActressSearchDropDown(val);
        handleSearchList(val, artistList);
    }

    const handleActressSearchDropDown = (val:string) => {
        if(val.length > 0) setShowActressSearchDropDown(true)
        else setShowActressSearchDropDown(false)
    }

    const onActressSelectChange = (data:any) => {
        setMovieDetails({
            ...movieDetails,
            actresses: data.name
        })
        setShowActressSearchDropDown(false)
    }

    const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(isNullOrEmpty(movieDetails.name))
        {
            let success = await uploadMovie(movieDetails);
            if(success) SetMovieUploadedSuccessfully(true);
        }
    }


    return(
        <div className="Artist mt-4">
            {
                (!movieUploadedSuccessfully) && 
                <form onSubmit={ e => handleOnSubmit(e) }>
                    <div className="grid grid-flow-row gap-2 place-items-center font-serif 
                                        bg-gradient-to-r from-pink-500 to-yellow-500
                                    text-gray-700 p-2">
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Movie Poster <InfoCard information="(Image must be vertical with format png,jpg,jpeg)"/>
                        </div>
                        <div className="">
                            <input type="file" className="rounded-md bg-white border-solid 
                                    border-4 border-yellow-500" name="file" onChange = {handleImageFile} />
                            {
                                (uploadClicked && uploadSuccess) && 
                                    <span>
                                        <button className=" p-1 ml-3 pl-3 pr-3  
                                            bg-yellow-900 rounded-md text-white" >
                                                        <TiTickOutline />  
                                        </button>
                                    </span>
                            }
                            {
                                (!uploadClicked) && 
                                    <span>
                                        <button className="p-1 ml-3 pl-3 pr-3  
                                            bg-yellow-900 rounded-md text-white" onClick={onClickUpload}>
                                                    Upload
                                        </button>
                                    </span>
                            }
                        </div>

                        <div className="font-bold text-xl mt-5">
                            Movie Name
                        </div>
                        <div>
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black place-self-center" 
                                    type="text" name="name" placeholder="Movie Name" value={movieDetails.name} onChange={e => onSearchName(e)}/>
                            
                            {
                                (showMovieSearchDropDown) && 
                                    <div>
                                        <DropDown optionList={searchList} onSelectChange={(e) => onMovieSelectChange(e)}/>
                                    </div>
                            }
                        </div>
                        {/**
                         * ****************************************
                         * Willbe populated default
                         */}
                        <div className="font-bold text-xl mt-5">
                            Movie Id
                        </div>
                        <div>
                            <input disabled className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                type="text" name="id" value={movieDetails.id} />
                        </div>
                        {/**
                         * ****************************************
                         * Willbe populated default
                         */}
                        <div className="font-bold text-xl mt-5">
                            Language
                        </div>
                        <div>
                            <div className="h-10 w-80 grid place-self-center relative">
                                <select className="block appearance-none w-full bg-gray-200 border 
                                        border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded 
                                        leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                        name="language" onChange={(e) => onSelectfromDropDown(e)} >
                                        <option >Select</option>
                                    {
                                        languages?.map((item:any)=>{
                                            return <option key={item} value={item} >{item} </option>
                                        })
                                    }
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-8 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                </div>
                            </div>
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Actor/s
                        </div>
                        <div>
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                type="text" name="actors" value={movieDetails.actors} onChange={e => onSearchActorName(e)} />
                            {
                                (showArtistSearchDropDown) && 
                                <div>
                                    <DropDown optionList={searchList} onSelectChange={(e) => onArtistSelectChange(e)}/>
                                </div> 
                            }
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Actress/s
                        </div>
                        <div>
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                type="text" name="actresses" value={movieDetails.actresses} onChange={e => onSearchActressName(e)} />
                                
                            {
                                (showActressSearchDropDown) && 
                                <div>
                                    <DropDown optionList={searchList} onSelectChange={(e) => onActressSelectChange(e)}/>
                                </div> 
                            }    
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Cast
                        </div>
                        <div className="">
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                type="text" name="cast" onChange={e => updateMovieDetails(e)} />
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Director/s
                        </div>
                        <div className="">
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                type="text" name="directors" onChange={e => updateMovieDetails(e)} />
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Writer/s
                        </div>
                        <div className="">
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                type="text" name="writers" onChange={e => updateMovieDetails(e)} />
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Producer/s
                        </div>
                        <div className="">
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                type="text" name="producers" onChange={e => updateMovieDetails(e)} />
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            ProductionHouse/s 
                        </div>
                        <div className="">
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                type="text" name="productionhouses" onChange={e => updateMovieDetails(e)} />
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Banner/s 
                        </div>
                        <div className="">
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                type="text" name="banners" onChange={e => updateMovieDetails(e)}/>
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Info
                        </div>
                        <div className="">
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                type="text" name="info" onChange={e => updateMovieDetails(e)}/>
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div>
                            ****************Genres***********************
                        </div>
                        <div>
                            Type Three most relavant Genres seperated by (,) from the drop down
                        </div>
                        <div className="font-bold text-xl mt-5">
                            Genres : 
                        </div>
                        <div className="h-10 w-80 grid place-self-center relative">
                            <select className="block appearance-none w-full bg-gray-200 border 
                                    border-gray-200 text-gray-700 py-1 px-2 pr-8 rounded 
                                    leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                    name="genres" >
                                {
                                    genres?.map((item:any)=>{
                                        return <option key={item} value={item} >{item} </option>
                                    })
                                }
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-8 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                        </div>
                        <div className="">
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                type="text" name="genres" onChange={e => updateMovieDetails(e)} 
                                placeholder="Fiction,Drama,Teen"/>
                        </div>
                        <div>
                            *********************************************
                         </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Theatre Release Date
                        </div>
                        <div className="">
                            {/* <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                type="text" name="releasedate" onChange={e => updateMovieDetails(e)}/> */}
                            <div className ="datepicker relative form-floating mb-3 xl:w-96">
                                <input type="date"
                                    className ="form-control block w-full px-3 py-1.5 text-base 
                                    font-normal text-gray-700 bg-white bg-clip-padding border 
                                    border-solid border-gray-300 rounded transition ease-in-out 
                                    m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 
                                    focus:outline-none" name='releasedate' onChange={e => updateMovieDetails(e)}
                                placeholder="Select a date" />
                            </div>
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div>
                            ******************OTT***********************
                        </div>
                            <div>
                                This section completely deals with OTT platform
                            </div>
                            <div className="font-bold text-xl mt-5">
                                Platform : 
                            </div>
                            <div className="h-10 w-80 grid place-self-center relative">
                                <select className="block appearance-none w-full bg-gray-200 border 
                                        border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded 
                                        leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                        name="platform" onChange={(e) => onSelectfromDropDown(e)} >
                                        <option >Select</option>
                                    {
                                        platform?.map((item:any)=>{
                                            return <option key={item} value={item} >{item} </option>
                                        })
                                    }
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-8 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                </div>
                            </div>
                            <div className="font-bold text-xl mt-5">
                                OTT Release Date: 
                                <div className ="datepicker relative form-floating mb-3 xl:w-96">
                                    <input type="date"
                                        className ="form-control block w-full px-3 py-1.5 text-base 
                                        font-normal text-gray-700 bg-white bg-clip-padding border 
                                        border-solid border-gray-300 rounded transition ease-in-out 
                                        m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 
                                        focus:outline-none" name='ottreleasedate' onChange={e => updateMovieDetails(e)}
                                    placeholder="Select a date" />
                                </div>
                            </div>
                        <div>
                            *********************************************
                         </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Tags <InfoCard information="(Tags must be added with ',' seperated)" />
                        </div>
                        <div className="">
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                type="text" name="tags" onChange={e => updateMovieDetails(e)}/>
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="text-slate-100 mb-10 mt-5">
                            <button className="p-1 pl-3 pr-3  bg-yellow-900 rounded-md mr-10">Cancel</button>
                            {
                                uploadClicked && 
                                <button type="submit" className="p-1 pl-3 pr-3  bg-yellow-900 rounded-md">Submit</button>
                            }
                            {
                                (!uploadClicked) && 
                                <button type="submit" className="p-1 pl-3 pr-3 bg-red-500 rounded-md" disabled>Submit</button>
                            }
                        </div>
                    </div>
                </form>
            }
            {
                (movieUploadedSuccessfully) && 
                    <div>
                        <SuccessCard />
                    </div>
            }
        </div>
    )
}

export default AddMovie;