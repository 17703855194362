import { useEffect, useState } from "react";
import { searchMovies } from "../helpers/search";
import {IMovieList} from "../entities/IList";
import {movies} from "../state/recoilBootstrapState"
import { useRecoilState } from "recoil";
import { getAllMoviesMetaData } from "../client/getData";
import { Link } from "react-router-dom";

const Search = () => {

    const[selectedVal , setSelectedValue] = useState("")
    const[ondropDown , setOndropDown] = useState(false)
    const[filteredList, setFilteredList] = useState<Array<IMovieList>>();
    const[moviesList, setMoviesList] = useRecoilState(movies)
   // const movies = list

    const onType = async(event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const val = event.currentTarget.value;
        setSelectedValue(val);
        let filtered = await searchMovies(val,moviesList)
        if(filtered.length !== 0) {
            setOndropDown(true)
            setFilteredList(filtered)
        }
        if(val.length === 0){
            setOndropDown(false)
        }
    }

    const onSelectVal = (event: React.MouseEvent<HTMLButtonElement>, selected:string) => {
        event.preventDefault();
        setSelectedValue(selected);
        setOndropDown(false);
    }

    const setMovies = async () => {
        let data = await getAllMoviesMetaData();
        setMoviesList(data!);
    }

    useEffect(() => {
        setMovies();
    },[])

    return(
        <div>
            <input className="h-8 w-80 rounded-md border-2 mr-3 p-2 text-black place-self-center" 
                    type="text" name="moviename" placeholder="Search" 
                     value={selectedVal}     onChange={e => onType(e)}/>
            {
                (ondropDown) && 
                    <div className="">
                        <div className="h-10 w-80 grid place-self-center relative z-10">
                            <ul>
                                {
                                    filteredList?.map((movie)=>(
                                        <li key={movie.name} value={movie.name} className="p-2 bg-slate-200 m-1">
                                            <nav>
                                                <Link to={"/movie/"+movie.id}>
                                                    <button> {movie.name} </button>
                                                </Link>
                                            </nav>
                                        </li>
                                    ))
                                }
                            </ul>
                            {/* <select className="block appearance-none w-full bg-gray-200 border 
                                    border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded 
                                    leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                    name="moviename" onChange={(e) => onMovieSelectChange(e)} >
                                {
                                    filteredList?.map((movie)=>(
                                        <option key={movie.name} value={movie.name} >{movie.name}</option>
                                    ))
                                }
                                
                            </select> */}
                            {/* <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-8 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div> */}
                        </div>
                    </div>
            }
        </div>
    )
}

export default Search;