import ArtistsCard from "../cards/ArtistsCard";
import { allArtistsList } from "../state/recoilBootstrapState";
import { useRecoilValue } from 'recoil';
import logo from '../logo/SHOWbuoy_logo.png'
import MetaData from './MetaData'

const Kystar = () => {
    const artists = useRecoilValue(allArtistsList);
    return(
        <div className="Artist bg-gray-200">
            <MetaData title="SHOWbuoy | Artists" img={logo} type="page"
                        headline="Find all the news and articles on Criket, IPL and latest Movies in Theaters and OTT" 
                        tags="movies, telugu movies, hindi movies, tamil movies, kannada movies, 
                                malayalam movies, top movies, Netflix, Prime Video, Sun Nxt, Aha Video,
                                Top Indian movies in Netflix, Disney + Hotstar, Youtube, Jio Movies, Zee 5,
                                Top Telugu Movies, Top Hindi Movies, Top Tamil Movies, Top Malayalam Movies,
                                sports, cricket, IPL, Mumbai Indians, Punjab Kings,
                                Delhi Capitals, Luknow Super Gaints, Kolkata knight riders,
                                Sunrisers Hyderabad, Royal Challengers Bangalore, Chennai Super Kings"
                            publishedtime = "Some time" 
            />
            <div className="p-2">
                <div className="flex flex-wrap gap-2 justify-center mx-10 bg-white p-3">
                    {
                        artists?.map((item) => (
                            <ArtistsCard  key={item.id} artist={item}/>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default Kystar;