import {TiTickOutline} from 'react-icons/ti';
import React, { useState } from "react";
import ICarouselImages from '../entities/ICarouselImages';
import { v4 as uuid } from 'uuid';
import { imagePath } from '../entities/constant';
import {uploadFile} from '../client/apiCall';
import SuccessCard from '../cards/SuccessCard';
import {isNullOrEmpty} from "../validations/validate";
import {uploadCarousel} from '../client/apiCall'

const AddCarouselImage = () => {
    const [uploadClicked, setUploadClicked] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadImage, setUploadImage] = useState<File>();
    const [carouselUploadedSuccessfully, SetCarouselUploadedSuccessfully] = useState(false);

    const [carouselImgDetails, setCarouselImgDetails] = useState<ICarouselImages>({
                                                                        id: uuid(),
                                                                        moviename:'',
                                                                        img:'',
                                                                        url:'' 
                                                                    })
    const updateCarouselImgDetails = (event: React.FormEvent<HTMLInputElement>): void => {
        setCarouselImgDetails({
            ...carouselImgDetails,
            [event.currentTarget.name]: event.currentTarget.value
        })
    }
    const setImgPath = (setImgPath) => {

        const val = imagePath + "/" + carouselImgDetails.id + "." + setImgPath;
        setCarouselImgDetails({
            ...carouselImgDetails,
            "img": val
        })
    }

    const handleImageFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (!event.currentTarget.files) return;
        const file: FileList = event.currentTarget.files;
        setUploadImage(file[0]);
        const size = file[0].name.split('.'); // returns png/jpeg
        const format = file[0].name.split('.')[size.length-1];
        console.log("in Handle Image");
        setImgPath(format);
    }

    const onClickUpload = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if(uploadImage === undefined)
        {
            alert("Choose a file to upload")
        }else{
            const formData = new FormData();
            formData.append("name", carouselImgDetails.id);
            formData.append("file",uploadImage!);
            let res = await uploadFile(formData);
            if(res) setUploadSuccess(true)
            setUploadClicked(true);
        }
    }

    const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log("carouselImgDetails : ", carouselImgDetails);
        if(isNullOrEmpty(carouselImgDetails.id))
        {
            let success = await uploadCarousel(carouselImgDetails);
            if(success) SetCarouselUploadedSuccessfully(true);
        }
    }
    return(
        <div>
            {
                (!carouselUploadedSuccessfully) &&
                <form onSubmit={ e => handleOnSubmit(e) }>
                    <div className="grid grid-flow-row gap-2 place-items-center font-serif 
                                        bg-gradient-to-r from-pink-500 to-yellow-500
                                    text-gray-700 p-2">
                        {/**
                         * ****************************************
                         * Willbe populated default
                         */}
                        <div className="font-bold text-xl mt-5">
                            Poster Id
                        </div>
                        <div>
                            <input disabled className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                type="text" name="id" value={carouselImgDetails.id} />
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Movie Poster
                        </div>
                        <div className="">
                            <input type="file" className="rounded-md bg-white border-solid 
                                    border-4 border-yellow-500" name="file" onChange = {handleImageFile} />
                            {
                                (uploadClicked && uploadSuccess) && 
                                    <span>
                                        <button className=" p-1 ml-3 pl-3 pr-3  
                                            bg-yellow-900 rounded-md text-white" >
                                                        <TiTickOutline />  
                                        </button>
                                    </span>
                            }
                            {
                                (!uploadClicked) && 
                                    <span>
                                        <button className="p-1 ml-3 pl-3 pr-3  
                                            bg-yellow-900 rounded-md text-white" onClick={onClickUpload}>
                                                    Upload
                                        </button>
                                    </span>
                            }
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Movie Name
                        </div>
                        <div className="">
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                type="text" name="moviename" onChange={e => updateCarouselImgDetails(e)} />
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Source Url
                        </div>
                        <div className="">
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                type="text" name="url" onChange={e => updateCarouselImgDetails(e)} />
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="text-slate-100 mb-10 mt-5">
                            <button className="p-1 pl-3 pr-3  bg-yellow-900 rounded-md mr-10">Cancel</button>
                            {
                                uploadClicked && 
                                <button type="submit" className="p-1 pl-3 pr-3  bg-yellow-900 rounded-md">Submit</button>
                            }
                            {
                                (!uploadClicked) && 
                                <button type="submit" className="p-1 pl-3 pr-3 bg-red-500 rounded-md" disabled>Submit</button>
                            }
                        </div>
                    </div>
                </form>
            }
            {
                (carouselUploadedSuccessfully) && 
                    <div>
                        <SuccessCard />
                    </div>
            }
        </div>
    )
}

export default AddCarouselImage;