import RatingCard from "../cards/RatingCard";
import UrlCard from "../cards/UrlCard";
import CarouselCard from "../cards/CarouselCard";
import {useRecoilValue} from "recoil";
import {allArticlesState, allCarouselState, allReviewsState} from "../state/recoilBootstrapState"
import NewsComponent from "../components/NewsComponent";
import logo from '../logo/SHOWbuoy_logo.png';
import MetaData from './MetaData'

const MobileHome = () => {
    const articlesList = useRecoilValue(allArticlesState);
    const carouselList = useRecoilValue(allCarouselState)!;
    const reviewsList = useRecoilValue(allReviewsState);

    return(
        <div>
            <MetaData title="SHOWbuoy | Indian Cinema" img={logo} type="page"
                        headline="Find all the news and articles on Criket, IPL and latest Movies in Theaters and OTT" 
                        tags="movies, telugu movies, hindi movies, tamil movies, kannada movies, 
                                malayalam movies, top movies, Netflix, Prime Video, Sun Nxt, Aha Video,
                                Top Indian movies in Netflix, Disney + Hotstar, Youtube, Jio Movies, Zee 5,
                                Top Telugu Movies, Top Hindi Movies, Top Tamil Movies, Top Malayalam Movies,
                                sports, cricket, IPL, Mumbai Indians, Punjab Kings,
                                Delhi Capitals, Luknow Super Gaints, Kolkata knight riders,
                                Sunrisers Hyderabad, Royal Challengers Bangalore, Chennai Super Kings"
                            publishedtime = "Some time" 
            />
            <div className="td:hidden MobileHome space-y-4 mx-1">
                {/* <div className="flex flex-row mt-2 p-3 space-x-16">
                    <div className="px-2 rounded-md bg-amber-700">
                    Today: MI vs CSK (CSK won by 3 wickets)
                    </div>
                    <div>
                    Tomorrow: PBKS VS DC
                    </div>
                </div> */}
                <div className=" mt-4 font-serif
                                rounded p-2">
                        {
                            <CarouselCard data={carouselList}/>
                        }
                </div>
                <div className="text-center text-lg bg-amber-700 rounded-xl relative">
                            <h1>Trending Movies</h1>
                            <a href="/movies" className="absolute right-0 bottom-1 pr-2 text-sm">show more</a>
                    </div>
                <div className="space-y-2">
                    <div className="h-96 py-4 space-y-2 bg-gray-700 rounded-xl 
                                    shadow-xl text-white overflow-y-auto d:scrollbar-hide">
                            {
                                reviewsList?.map((review) => (
                                    <RatingCard key={review.reviewid} review={review} />
                                ))
                            }
                    </div>
                    <div className="text-center text bg-amber-700 rounded-xl p-1 relative">
                        <h1> News </h1>
                    </div>

                    <div className="m-x-10">
                        {
                           <NewsComponent type="news"/>
                        }
                    </div>
                    
                    <div className="text-center text bg-amber-700 rounded-xl p-1 relative">
                        <h1> Sports</h1>
                        <a href="/sports" className="absolute right-0 bottom-1 pr-2 text-sm">show more</a>
                    </div>

                    <div className="h-96 mt-3 space-y-2 bg-gray-700 rounded-xl 
                                    shadow-xl text-white overflow-y-auto d:scrollbar-hide">
                            <div className="p-1 m-x-10">
                                {
                                    <NewsComponent type="sports"/>
                                }
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default MobileHome;