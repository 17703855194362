import React, { useState } from "react";
import { IMovieList } from "../entities/IList";
import { useRecoilValue } from 'recoil';
import { allMoviesMetaState } from '../state/recoilBootstrapState';
import IMovieReview from "../entities/IMovieReview";
import InfoCard from "../cards/InfoCard";
import { v4 as uuid } from 'uuid';
import SuccessCard from "../cards/SuccessCard";
import {isNullOrEmpty} from "../validations/validate";
import {uploadReview} from "../client/apiCall"

const AddReview = () => {
    const [uploadedSuccessfully, SetUploadedSuccessfully] = useState(false);
    const [movieReview, setMovieReview] = useState<IMovieReview>({
                                                        moviename: '',
                                                        movieid: '',
                                                        reviewid: uuid(),
                                                        criticrating: 0.0,
                                                        reviewheader: '',
                                                        reviewfirst: '',
                                                        reviewinterval: '',
                                                        reviewsecond: '',
                                                        reviewconclusion: '',
                                                        img:''
                                                     });
    // Get the artists and movies data already fetched when website launched
    const moviesList = useRecoilValue(allMoviesMetaState);
    
    const [showMovieSearchDropDown, setShowMovieSearchDropDown] = useState(false);
    const [searchList, setSearchList] = useState<Array<IMovieList>>();
    
    const updateMovieReview = (event: React.FormEvent<HTMLInputElement>): void => {
        setMovieReview({
            ...movieReview,
            [event.currentTarget.name]: event.currentTarget.value
        })
    }
    const updateReviewBody= (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setMovieReview({
            ...movieReview,
            [event.currentTarget.name]: event.currentTarget.value
        })
    }


    const onMovieSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {

        event.preventDefault();
        let selectedMovie: Array<IMovieList> = moviesList?.filter((m) => {
                                                    return(m.name === event.currentTarget.value)
                                                })!;

        setMovieReview({
            ...movieReview,
            [event.currentTarget.name]: event.currentTarget.value,
            "movieid": selectedMovie[0].id,
            "reviewid": selectedMovie[0].id
        })
        setShowMovieSearchDropDown(false);
    }

    const onSearchName = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const val = event.currentTarget.value;

        setMovieReview({
            ...movieReview,
            [event.currentTarget.name]: event.currentTarget.value
        })
        handleShowMovieSearchDropDown(val);
        handleSearchList(val, moviesList);
    }

    const handleShowMovieSearchDropDown = (val:string) => {
        if(val.length > 0) setShowMovieSearchDropDown(true)
        else setShowMovieSearchDropDown(false)
    }

    const handleSearchList = (val:string, data: any) => {
        let filteredData = [];
        let len = val.length;
        filteredData = data?.filter((item) => {
                    return(item.name.trim().length >= len && item.name.trim().slice(0,len).toLowerCase() === val.toLowerCase()) 
            })

        setSearchList(filteredData);
        if(filteredData?.length === 0 ) {
            setShowMovieSearchDropDown(false);
        }
    }

    const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(isNullOrEmpty(movieReview.moviename))
        {
            let success = await uploadReview(movieReview);
            if(success) SetUploadedSuccessfully(true);
        }
    }

    return(
        <div className="mt-4">
            {
                (!uploadedSuccessfully) &&
                    <form onSubmit={ e => handleOnSubmit(e) }>
                        <div className="grid grid-flow-row gap-2 place-items-center font-serif 
                                        bg-gradient-to-r from-pink-500 to-yellow-500
                                    text-gray-700">
                            {/**
                             * ****************************************
                             */}
                            <div className="font-bold text-xl mt-5">
                                Movie Name <InfoCard information="(type to search movie)" />
                            </div>
                            <div>
                                    <input className="h-8 w-80 rounded-md mr-3 p-2 text-black place-self-center" 
                                            type="text" name="moviename" placeholder="Movie Name" 
                                               value={movieReview.moviename}  onChange={e => onSearchName(e)}/>
                                    {
                                        (showMovieSearchDropDown) && 
                                            <div>
                                                <div className="h-10 w-80 grid place-self-center relative">
                                                    <select className="block appearance-none w-full bg-gray-200 border 
                                                            border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded 
                                                            leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                                            name="moviename" onChange={(e) => onMovieSelectChange(e)} >
                                                            <option >Select</option>
                                                        {
                                                            searchList?.map((movie)=>(
                                                                <option key={movie.name} value={movie.name} >{movie.name}</option>
                                                            ))
                                                        }
                                                        
                                                    </select>
                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                        <svg className="fill-current h-8 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                            {/**
                             * ****************************************
                             * Willbe populated default
                             */}
                            <div className="font-bold text-xl mt-5">
                                Movie Id : {movieReview.movieid}
                            </div>
                            {/* <div>
                                <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                    name="movieid" value={movieReview.movieid} onChange={e => updateMovieReview(e)}
                                     type="text" disabled/>
                            </div> */}
                            {/**
                             * ****************************************
                             */}
                            <div className="font-bold text-xl mt-5">
                                Review Id : {movieReview.reviewid}
                            </div>
                            {/* <div>
                                <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                name="reviewid" value={movieReview.reviewid} type="text" disabled/>
                            </div> */}
                            {/**
                             * ****************************************
                             */}
                            <div className="font-bold text-xl mt-5">
                                Critic Rating <InfoCard information="(maximum of 5)" />
                            </div>
                            <div>
                                <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                name="criticrating" type="text" onChange={e => updateMovieReview(e)}/>
                            </div>
                            {/**
                             * ****************************************
                             */}
                            <div className="font-bold text-xl mt-5">
                                Review Headline
                            </div>
                            <div className="">
                                <textarea className="w-80 h-24 min-w-full max-w-2xl rounded-md mr-3 p-2" 
                                        name="reviewheader" placeholder="Movie Header" onChange={e => updateReviewBody(e)}> </textarea>
                            </div>
                            {/**
                             * ****************************************
                             */}
                            <div className="font-bold text-xl mt-5">
                                (Para 1)
                            </div>
                            <div className="">
                                <textarea className="w-96 h-64 min-h-0 min-w-full max-w-2xl rounded-md mr-3 p-2" 
                                name="reviewfirst" placeholder="First Half" onChange={e => updateReviewBody(e)}> </textarea>
                            </div>
                            {/**
                             * ****************************************
                             */}
                            <div className="font-bold text-xl mt-5">
                                (Para 2)
                            </div>
                            <div className="">
                                <textarea className="w-96 h-64 min-h-0 min-w-full max-w-2xl rounded-md mr-3 p-2" 
                                name="reviewinterval" placeholder="Interval Backdrop" onChange={e => updateReviewBody(e)}></textarea>
                            </div>
                            {/**
                             * ****************************************
                             */}
                            <div className="font-bold text-xl mt-5">
                               (Para 3)
                            </div>
                            <div className="">
                                <textarea className="w-96 h-64 min-h-0 min-w-full max-w-2xl rounded-md mr-3 p-2" 
                                name="reviewsecond" placeholder="Second Half" onChange={e => updateReviewBody(e)}></textarea>
                            </div>
                            {/**
                             * ****************************************
                             */}
                            <div className="font-bold text-xl mt-5">
                                Conclusion (Para 4)
                            </div>
                            <div className="">
                                <textarea className="w-96 h-24 min-h-0 min-w-full max-w-2xl rounded-md mr-3 p-2" 
                                name="reviewconclusion" placeholder="Conclusion" onChange={e => updateReviewBody(e)}></textarea>
                            </div>
                            {/**
                             * ****************************************
                             */}
                            <div className="text-slate-100 mb-10">
                                <button type="submit" className="p-1 pl-3 pr-3  bg-yellow-900 rounded-md mr-10">Cancel</button>
                                <button type="submit" className="p-1 pl-3 pr-3  bg-yellow-900 rounded-md">Submit</button>
                            </div>
                        </div>
                    </form>
                }
                {
                    (uploadedSuccessfully) && 
                        <div>
                            <SuccessCard />
                        </div>
                }
        </div>
    )
}

export default AddReview;