import { useEffect, useState } from "react";
import { getMovieById } from "../client/getData";
import { useParams } from "react-router-dom";
import IMovie from "../entities/IMovie";
import Rating from "../components/Rating";
import IMovieReview from "../entities/IMovieReview";
import {getReviewDatabyId} from "../client/getData";
import { Link } from "react-router-dom";

const MobileMovie = () => {
    const {id} = useParams();
  //01070279-233f-418c-b92a-1234c881d3ad  
    const [movieData, setMovieData] = useState<IMovie>();
    const [review, setReview] = useState<IMovieReview>();

    const fetchData = async(id:string) => {
        let data = await getMovieById(id);
        setMovieData(data!);
    }

    const setReviewAsync = async(id:string) => {
        const reviewData = await getReviewDatabyId(id!);
        setReview(reviewData!);
    }


    let img;
    let cast;
    let url;
    if(movieData !== undefined){
        img = "https://surfserver.in" + movieData.img; 
        cast = movieData.actors + "," + movieData.actresses + "," + movieData.cast;
    }
    if(review !== undefined)
    {
        url = "/review/" + review.moviename+"/"+review.reviewid;
        url = url.replace(/\s/g, '-')
    }

  useEffect(()=>{
    fetchData(id!);
    setReviewAsync(id!);
  },[id])
    return(
        <div className="flex justify-center">
            {
                (movieData === undefined) && 
                <div className="flex justify-center rounded m-2 w-48 h-9">
                    Processing...
                </div>
            }
            {
                (movieData !== undefined) &&  
                <div className="flex flex-col">
                    <img className="object-contain h-96 p-2 rounded-xl" src={img} alt="Not Found" />
                    <div className="m-5 px-2 ">
                        {
                            (review !== undefined) && 
                            <Rating rating={review?.criticrating!} />
                        }
                    </div>
                    <div className="flex p-3 mt-1 mx-1 justify-center bg-slate-800 text-white rounded-xl">
                        { (review === undefined || review!.reviewconclusion.length === 0) ? "TBA" : review!.reviewconclusion}
                    </div>
                    {
                        (review !== undefined) && 
                        <div className="flex p-2 mx-1 justify-center bg-slate-800 text-white -translate-y-4 rounded-lg">
                            <nav>
                                <Link to={url}>
                                    <p className="bg-green-700 hover:bg-green-500 d:px-3 
                                d:ml-4 rounded-full border-green-300 border-2 hover:border-green-500 text-sm hover:text-base px-1">Full Review</p>
                                </Link>
                            </nav>
                        </div>
                    }
                    
                    <div className="flex flex-col m-5">
                        <div className="flex justify-center ">
                            <div className="flex flex-col space-y-2 w-56 ">
                                <div>
                                    <b> Movie Name </b>: {movieData.name}
                                </div>
                                <div>
                                    <b>Director </b>: {movieData.directors}
                                </div>
                                <div>
                                    <b>Cast </b>: {cast}
                                </div>
                                <div>
                                    <b>Writer/s </b>: {movieData.writers}
                                </div>
                                <div>
                                    <b>Producer/s </b>: {movieData.producers}
                                </div>
                                <div>
                                    <b>Banner </b>: {movieData.banners}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 space-y-2">
                        <div className="p-2 rounded space-y-1">
                            <b>Info </b>
                            <div className="p-2 rounded text-lg text-white bg-gradient-to-r from-cyan-500 to-blue-500 whitespace-pre-wrap">
                                {movieData.info}
                            </div>
                        </div>
                        
                        <div className="p-2 rounded space-y-1">
                            <b>Where can I watch the movie?</b>
                            <hr></hr>
                            <div className="p-2 text-lg tracking-wide rounded text-white bg-gradient-to-r from-cyan-500 to-blue-500">
                                {
                                    movieData.platform.length === 0 ? "TBA" : movieData.platform
                                }
                            </div>
                        </div>
                        {/* <div className="p-2 rounded space-y-1">
                            <b>Interesting Facts:</b>
                        </div> */}
                    </div>
                </div>
            }
        </div>
    )
}

export default MobileMovie;