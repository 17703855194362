import Header from './components/Header'
import MobileHeader from './components/MobileHeader';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import Main from './Main';
import React from 'react';
import LoadingPage from './pages/LoadingPage'
import logo from './logo/SHOWbuoy_logo.png'
import MetaData from './pages/MetaData'

const App = () => {
  
  return(
    <div>
      <MetaData title="SHOWbuoy | Indian Cinema" img={logo} type="page"
                        headline="Find all the news and articles on Criket, IPL and latest Movies in Theaters and OTT" 
                        tags="movies, telugu movies, hindi movies, tamil movies, kannada movies, 
                                malayalam movies, top movies, Netflix, Prime Video, Sun Nxt, Aha Video,
                                Top Indian movies in Netflix, Disney + Hotstar, Youtube, Jio Movies, Zee 5,
                                Top Telugu Movies, Top Hindi Movies, Top Tamil Movies, Top Malayalam Movies,
                                sports, cricket, IPL, Mumbai Indians, Punjab Kings,
                                Delhi Capitals, Luknow Super Gaints, Kolkata knight riders,
                                Sunrisers Hyderabad, Royal Challengers Bangalore, Chennai Super Kings"
                            publishedtime = "Some time" 
            />
      <RecoilRoot>
        <BrowserRouter>
          <Header/>
          <MobileHeader />
          <React.Suspense fallback={<LoadingPage />}>
            <Main />
          </React.Suspense>
        </BrowserRouter> 
      </RecoilRoot>
    </div>
  )
}

export default App;
