import {HiChevronLeft, HiChevronRight} from 'react-icons/hi';
import {BsCircle} from 'react-icons/bs'
import {MouseEvent, useState, useEffect, useRef } from 'react';
import ICarouselImages from "../entities/ICarouselImages";

let copyCounter = 0;

interface Props{
    data:Array<ICarouselImages>
}

const CarouselCard = ({data} : Props) => {

    const [active, setActive] = useState(false);
    
    let counter = useRef(0).current;

    const BaseUrl = "https://surfserver.in"
    const displayImg = data.map((item)=> {
        return {img: BaseUrl + item.img,
                url: item.url}
    });

    const [image, SetImage] = useState(displayImg[counter]);
    
    let maxIndex = displayImg.length -1 ;
    
    const manageCarouselLeft = (event: MouseEvent<HTMLButtonElement> ) => {
        event.preventDefault();
        copyCounter = copyCounter - 1;
        if(copyCounter < 0){
            copyCounter = maxIndex
        }
        counter = copyCounter
        SetImage(displayImg[copyCounter]);
        clearInterval(timer)
    }

    const manageCarouselright = (event: MouseEvent<HTMLButtonElement> ): void => {
        event.preventDefault();
        copyCounter = copyCounter + 1;
        if(copyCounter > maxIndex){
            copyCounter = 0
        }
        counter = copyCounter
        SetImage(displayImg[copyCounter]);
        clearInterval(timer)
    }

    const callEvery7Seconds = () => {
        counter = counter + 1;
        if(counter > maxIndex){
            counter = 0
        }
        copyCounter = counter;
        SetImage(displayImg[counter]);
    }
    let timer;

    useEffect(() => {
        timer = setInterval(() => {
            callEvery7Seconds()
        },7000)
        return () => clearInterval(timer)
    },[])

    return(
        <div className="relative justify-center w-full rounded">
            {
                (data) && (
                    <div>
                        <div className="flex d:h-70 mt:h-54 justify-center">
                            <a className="rounded" href={image.url} rel="noreferrer" target="_blank">
                                <img src={image.img} className="object-contain object-center d:h-96 mt:h-54
                                                rounded-xl transition duration-150 ease-out hover:ease-in" 
                                alt="Not available" />
                            </a>
                        </div>
                        <div className=' grid grid-flow-col place-content-center gap-2 mt-1'>
                            <div>
                                <button className="" onClick={manageCarouselLeft}>
                                    <HiChevronLeft size="50" className="" />
                                </button>
                            </div>
                            {
                                displayImg.map((item,i) => {
                                    if(i === copyCounter)
                                    {
                                        return(
                                            <div key={i} className='grid place-self-center rounded-full'>
                                                <BsCircle className="bg-orange-500 rounded-full" />
                                            </div>
                                        )
                                        
                                    }else{
                                        return(
                                            <div key={i} className='grid place-self-center rounded-full'>
                                                <BsCircle className="rounded-full" />
                                            </div>
                                        )
                                    }
                                })
                            }
                            <div>
                                <button className="" onClick={manageCarouselright}>
                                    <HiChevronRight size="50" className=""/>
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
            
        </div>
    )
}

export default CarouselCard;