
const isNullOrEmpty = (data: any) => {
    if(typeof data == "undefined")
    {
        return false;
    }
    if(typeof data == "string"){
        if(data.length === 0 || data == null)
        {
            return false;
        }
    }
    if(typeof data == "number"){
        if(data === 0)
        {
            return false
        }
    }
    return true;
}

export {
    isNullOrEmpty
}
