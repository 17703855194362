import { Link } from "react-router-dom";
import IMovieReview from "../entities/IMovieReview";

interface Props{
    review: IMovieReview
}

const RatingCard = ({review} : Props) => {

    let img = "https://surfserver.in" + review.img;   
    let url = "/review/" + review.moviename+"/"+review.reviewid;
    url = url.replace(/\s/g, '-')

    return(
        <div className="ProfileCard ">
            <div className="bg-cyan-700 td:h-24 m:h-28  rounded-xl m-1 shadow-xl">
                <div className="flex flex-row ring-1 ring-purple-500 ring-offset-1 ring-offset-purple-100 rounded-xl">
                    <div className="basis-1/5 self-center p-2">
                        <img src={img}
                                        alt="aji" className="w-32 td:h-20 m:h-24 object-contain rounded-xl" />
                    </div>
                    <div className="basis-4/5 relative mr-2 flex items-center justify-center">
                        <div className="italic inline-block text-sm">
                          <b> {review.moviename} Review:{review.reviewheader.slice(0,100)} </b>
                        </div>
                        
                        <div className="inline-flex items-center absolute bottom-0 right-0">
                            <p className="text-orange-300 text-sm mx-1">SHOWbuoy Rating: {review.criticrating}</p>	
                            <nav>
                                <Link to={url}>
                                    <p className="bg-green-700 hover:bg-green-500 d:px-3 
                                d:ml-4 rounded-full border-green-300 border-2 hover:border-green-500 text-sm hover:text-base px-1">Read More</p>
                                </Link>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RatingCard;