
interface Props{
    optionList:any,
    onSelectChange: Function
}

const DropDown = ({optionList, onSelectChange}:Props) => {

    const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        let selectedOption;
        for(let i=0;i<optionList.length;i++){
            if(optionList[i].name === event.currentTarget.value)
            {
                selectedOption = optionList[i]
                break;
            }
        }
        onSelectChange(selectedOption); // This returns the entire object of selected option
    }

    return(
        <div>
            <div className="h-10 w-80 grid place-self-center relative">
                <select className="block appearance-none w-full bg-gray-200 border 
                        border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded 
                        leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                        name="name" onChange={(e) => onSelect(e)} >
                        <option >Select</option>
                    {
                        optionList?.map((item:any)=>{

                            if(item.hasOwnProperty("alias")) {
                               return <option key={item.name} value={item.name} >{item.alias} / {item.name} </option>
                            }
                            else{
                               return <option key={item.name} value={item.name} >{item.name}</option>
                            }
                        })
                    }
                    
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-8 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
            </div>
        </div>
    )
}

export default DropDown