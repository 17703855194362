import { useParams } from "react-router-dom";
import { useEffect,useState } from "react";
import {getReviewDatabyId} from "../client/getData"
import IMovieReview from "../entities/IMovieReview";
import MetaData from './MetaData'
import Rating from "../components/Rating";

const MovieReview = () => {
    const {id} = useParams();
    const [review, setReview] = useState<IMovieReview>();

    const setReviewAsync = async() => {
        const reviewData = await getReviewDatabyId(id!);
        setReview(reviewData!);
    }

    let img = "https://surfserver.in" + review?.img; 
    
    useEffect(() => {
        setReviewAsync();
    },[]);// Ignore warning

    return(
        <div className="bg-gray-200 ">
            {
               (review) && (
                    <MetaData title={review?.reviewheader} img={img} type="article"
                                headline={review?.reviewfirst} tags={review.moviename}
                                publishedtime = "Some time" />
               ) 
            }

            {
                (review === undefined) && 
                    <div className="animate-pulse text-center">
                        Loading ...
                    </div>
            }
            {
                (review) && 
                <div className="flex flex-row">
                    <div className="m:hidden basis-1/6">
                        <div className="flex justify-center">
                        </div>
                    </div>
                    <div className="td:basis-3/6 m-3 p-3 bg-white rounded-xl">
                        <div className="m:hidden flex justify-center">
                            <img className="h-80 w-64 float-none rounded-xl" src={img} alt="Not Found" />
                            <div className="p-2">
                                <span>
                                   <b>{review?.moviename} review : {review?.reviewheader} </b>
                                </span>
                                <hr></hr>
                                <br></br>
                                <span className="whitespace-pre-wrap">
                                    {review?.reviewfirst}
                                </span>
                            </div>
                        </div>

                        <div className="td:hidden flex justify-center">
                            <img className="h-64 w-36 float-none rounded-xl" src={img} alt="Not Found" />
                            <div className="p-2">
                                <span className="whitespace-pre-wrap">
                                    <b>{review?.moviename} review : {review?.reviewheader} </b>
                                </span>
                                <hr></hr>
                                <span className="whitespace-pre-wrap">
                                    {review?.reviewfirst}
                                </span>
                            </div>
                        </div>
                        {/* <div>
                            <span>
                                {review?.reviewfirst}
                            </span>
                        </div> */}

                        <div className="mt-4">
                            <span className="whitespace-pre-wrap">
                                {review?.reviewinterval}
                            </span>
                        </div>
                        <div className="mt-4">
                            <span className="whitespace-pre-wrap">
                                {review.reviewsecond}
                            </span>
                        </div>
                        <div className="mt-4 p-1 text-white bg-orange-800 rounded">
                            <span className="whitespace-pre-wrap">
                                {review.reviewconclusion}
                            </span>
                        </div>
                        <div className="mt-4 p-1">
                            <Rating rating={review.criticrating}/>
                        </div>
                    </div>

                    {/* <div className="m:hidden basis-2/6 flex flex-col mx-3 gap-y-2 gap">
                        <div className="text-center text-lg font-bold text-orange-900">
                            Relavant Articles
                        </div>
                        <div className="flex h-32 justify-center bg-slate-800 text-white rounded-xl">
                            News Articles
                        </div>
                        <div className="flex h-32 justify-center bg-slate-800 text-white rounded-xl">
                            News Articles
                        </div>
                    </div> */}
                </div>
            }
            {/* <div className="td:hidden basis-2/6 flex flex-col mx-3 gap-y-2 gap">
                <div className="text-center text-lg font-bold text-orange-900">
                    Relavant Articles
                </div>
                <div className="flex h-32 justify-center bg-slate-800 text-white rounded-xl">
                    News Articles
                </div>
                <div className="flex h-32 justify-center bg-slate-800 text-white rounded-xl">
                    News Articles
                </div>
            </div> */}
            
        </div>
    )
} 

export default MovieReview;