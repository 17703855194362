
interface Props{
    information: string
}

const InfoCard = ({information} : Props) => {
    return(
        <span className="italic text-sm">{information}</span>
    )
}

export default InfoCard