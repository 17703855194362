
export const Menu = [
    "Home",
    "Movies",
    "know your ARTIST",
    "know your ART",
    "one CHANCE"
];

export const Leagues = [
    "IPL",
    // "BBL",
    // "CPL",
    // "PSL",
    // "TPL",
    // "KPL"
]

export const Publisher = [
    "Select",
    "Add Artist",
    "Add Art",
    "Add Movie",
    "Add Review",
    "Add Job",
    "Add Article",
    "Add Carousel Image",
    "Add Sports"
]

export const Editor = [
    "Select",
    "Edit Artist",
    "Edit Art",
    "Edit Movie",
    "Edit Review",
    "Edit Job",
    "Edit Article",
    "Edit Carousel Image",
    "Edit Sports"
];

export enum ArticleStatus {
    Ready = "Ready",
    Review = "Review",
    Done = "Done"
  } 

export const maritalStatus = [
    "UnKnown",
    "Married", 
    "UnMarried", 
    "Divorced" 
]

export const languages = [
    "Multiple",
    "Hindi",
    "Telugu",
    "Tamil",
    "Kannada",
    "Malayalam",
    "Marathi",
    "Punjabi"
]

export const platform = [
    "NetFlix",
    "Prime Video",
    "Zee5",
    "Aha",
    "Disney + Hotstar",
    "sun NXT",
    "Spark",
    "HBO MAX",
    "JIO Cinema"
]

export const genres = [
    "Fiction",
    "Mystery",
    "Thriller",
    "Horror",
    "Historical",
    "Romance",
    "Western",
    "Drama",
    "Teen",
    "Fantasy",
    "Realistic"
]

export const ArticleType = [
    "Brief",
    "Article"
]

export const ArticleSubType = [
    "Movie",
    "Sports"
]

export const imagePath = '/images'