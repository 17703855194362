import {useState } from "react";
import Login from "../forms/Login";
import { Link } from "react-router-dom";
import logo from '../logo/SHOWbuoy_logo.png'
import UserProfileCard from '../cards/UserProfileCard'
import {loggedInState,isAdmin} from "../state/userState"
import { useRecoilState } from "recoil";

const Header = () => {

    const [loginClicked, setLoginClicked] = useState(false);
    const [isAdminTrue, setAdminTrue] = useRecoilState(isAdmin);
    
    const updateLoginClicked = () => {setLoginClicked(!loginClicked)}
    const [loggedIn, setLoggedIn] = useRecoilState(loggedInState);

    return(
        <div className="m:hidden Header bg-slate-800 text-gray-300">
            <div className="flex flex-row">
                <div className="basis-1/5 my-3">
                    <div className="flex justify-end brightness-200">
                        <nav>
                            <Link to="/home" >
                                <img className="rounded h-20 w-full object-contain" 
                                        src={logo} alt=""/>
                            </Link>
                        </nav>
                    </div>
                </div>
                
                <div className="basis-4/5 ml-16 relative">
                    {/*
                    *   First Row
                    */}
                    {/*<div>
                        <div className="grid grid-flow-col mt-2">
                             <div className="place-self-end col-span-1">
                                <input className="h-8 rounded-md mr-3 p-2 text-black" type="text" 
                                        placeholder="Search"/>
                                <button className="h-8 px-3 place-self-center border-2 rounded-md"> 
                                            Search 
                                </button>
                            </div> 
                        </div>   
                    </div> */}

                    {/**
                     * Second Row
                     */}
                     {/* <div className="flex flex-row mt-2 p-3 space-x-16">
                         <div className="px-2 rounded-md bg-amber-700">
                            Today: MI vs CSK (CSK won by 3 wickets)
                         </div>
                         <div>
                            Tomorrow: PBKS VS DC
                         </div>
                     </div> */}
                    <div className="flex flex-row absolute bottom-3 space-x-16">
                        <div className="rounded-md hover:px-2
                                            hover:bg-green-500 hover:text-white hover:border-transparent">
                            <nav>
                                <Link to="/home">
                                    <button className="">
                                            Home
                                    </button>
                                </Link>
                            </nav>
                        </div>
                        <div className="rounded-md hover:px-2
                                        hover:bg-green-500 hover:text-white hover:border-transparent">
                            <nav>
                                <Link to="/movies">
                                    <button className="">
                                            Movies
                                    </button>
                                </Link>
                            </nav>
                        </div>
                        <div className="rounded-md hover:px-2
                                        hover:bg-green-500 hover:text-white hover:border-transparent">
                            <button>
                                <nav>
                                    <Link to="/sports">
                                        <span className="">Sports</span>
                                    </Link>
                                </nav>
                            </button>
                        </div>
                        <div className="rounded-md hover:px-2
                                        hover:bg-green-500 hover:text-white hover:border-transparent">
                            <button>
                                <nav>
                                    <Link to="/artist">
                                        <span>Know Your </span>
                                        <span className="text-base text-amber-300 font-semibold">STAR</span>
                                    </Link>
                                </nav>
                            </button>
                        </div>
                        <div className="hidden rounded-md hover:px-2
                                        hover:bg-green-500 hover:text-white hover:border-transparent">
                            <button>
                                <nav>
                                    <Link to="/art">
                                        <span>Know Your </span>
                                        <span className="text-base text-amber-300 font-semibold">ART</span>
                                    </Link>
                                </nav>
                            </button>
                        </div>
                        {
                            (isAdminTrue) && 
                                <div className="rounded-md hover:px-2
                                                hover:bg-green-500 hover:text-white hover:border-transparent"> 
                                <button className="rounded-md"> 
                                    <nav>
                                        <Link to="/edit" >Edit</Link>
                                    </nav>          
                                </button>
                            </div>
                            }
                            {
                            (isAdminTrue) &&
                                <div className="rounded-md hover:px-2
                                                    hover:bg-green-500 hover:text-white hover:border-transparent"> 
                                    <button className="rounded-md"> 
                                        <nav>
                                            <Link to="/addnew" >Add</Link>
                                        </nav>          
                                    </button>
                                </div>
                            }
                            {
                                (isAdminTrue) &&
                                <div className="rounded-md hover:px-2
                                                    hover:bg-green-500 hover:text-white hover:border-transparent"> 
                                <button className="rounded-md"> 
                                    <nav>
                                        <Link to="/workflow">workflow</Link>
                                    </nav>          
                                </button>
                            </div>

                            }
                            {
                                (!isAdminTrue) &&
                                <div className="rounded-md hover:px-2
                                                hover:bg-green-500 hover:text-white hover:border-transparent"> 
                                
                            </div>
                            }
                        
                        <div className="rounded-md hover:px-2
                                            hover:bg-green-500 hover:text-white hover:border-transparent">
                            {
                                (!loggedIn) &&
                                    <button className=" px-1 rounded-md" 
                                            onClick={updateLoginClicked}>Log In
                                    </button>
                            }
                            {
                                (loggedIn) && 
                                <button className=" place-self-center ">
                                    <UserProfileCard/>
                                </button>
                            }
                        </div> 
                    </div>
                </div>
            </div>
                <div>
                    {
                        loginClicked && <Login updateLoginClicked= {updateLoginClicked}/>
                    }
                </div>
        </div>
    )
}

export default Header;