import PointCard from "../cards/PointCard";
import {pointsState} from "../state/recoilBootstrapState";
import { useRecoilState } from "recoil";
import { Iipl } from "../entities/Iipl";

const PointsTable = () => {
    const [pointsState_, setPointsState] = useRecoilState(pointsState);

    return(
        <div className="m-1">
            
            <div className="Header grid grid-cols-8 grid-flow-col gap-1 bg-cyan-700 rounded place-items-center mb-4">
                <div className="ml-1 mr-2">
                    Team
                </div>
                <div>
                    Mat
                </div>
                <div>
                    Won
                </div>
                <div>
                    Lost
                </div>
                <div>
                    Tied
                </div>
                <div>
                    NR
                </div>
                <div>
                    Pts
                </div>
                <div>
                    NRR
                </div>
            </div>
            
            <div className="grid space-y-2">
                {
                   (pointsState_) && pointsState_.map((item:Iipl)=>(
                        <PointCard key={item.teamname} data={item}/>
                    ))
                }
            </div>
        </div>
    )
}

export default PointsTable