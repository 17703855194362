import IMovie from "../entities/IMovie";
import MovieCard from "./MovieCard";
import {CgChevronDoubleDownR} from 'react-icons/cg';
import { Link } from "react-router-dom";

interface Props{
    ItemKey:string,
    ItemValue:Array<IMovie>,
    Reference: string
}


const OttCard = ({ItemKey, ItemValue, Reference}: Props) => {
    
    return(
        <div id={Reference} className="grid grid-flow-row mt-5 rounded gap-2 p-2 text-black" >
            <div className="text-green-700">
                {ItemKey} <button className='ml-5 animate-bounce'> 
                            <CgChevronDoubleDownR />   
                        </button>
            </div>
            <div className="grid grid-flow-col overflow-x-auto auto-cols-max gap-3 p-1">
                {
                    ItemValue.map((movie) => {
                        let url = "/movie/"+movie.id;
                        return (
                            <div key={movie.id} className='grid place-self-center' >
                                <nav>
                                    <Link to={url}>
                                        <MovieCard movie={movie} keyVal={movie.id} key={movie.id}/>
                                    </Link>
                                </nav>
                            </div>
                        )}
                    )
                }
            </div>
        </div>
    )
}

export default OttCard