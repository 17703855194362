import { atom, selector } from "recoil";


const loggedInState = atom({
    key: 'loggedIn',
    default: false,
  });

  const isAdmin = atom({
    key: 'isAdmin',
    default: false,
  })

  export {
    loggedInState,
    isAdmin
  }
