import { FaFacebookSquare } from 'react-icons/fa';
import {FaYoutube} from 'react-icons/fa';
import {AiFillInstagram} from 'react-icons/ai';
import {FaTwitter} from 'react-icons/fa';
import {languages} from '../entities/constant'

const Footer = () => {
    return(
        <div className='Footer m:text-sm mt-3'>
            <div className="bg-slate-700 mb-1 text-white text-center">
                <div className="p-4">
                    © 2022 Copyright :  &nbsp;
                    <a className="underline underline-offset-2 text-green-500" 
                            rel="noreferrer" target="_blank" href="https://showbuoy.com"> 
                         SHOWbuoy | Indian Cinema</a>
                         &nbsp;
                    powered by 
                    &nbsp;
                    <a className="underline underline-offset-2 text-green-500" 
                            rel="noreferrer" target="_blank" href="http://webent.co.in"> 
                    Webent Technologies</a>
                         &nbsp;
                    
                </div>
                <div className="flex flex-row td:p-4 m:p-1">
                    
                    <div className="basis-1/4 space-y-1 p-1">
                        <div>
                            About
                        </div>
                        <div>
                            Objective
                        </div>
                        <div>
                            Motive
                        </div>
                        <div>
                            Careers
                        </div>
                    </div>

                    <div className="basis-1/4 space-y-1 p-1">
                        {
                            languages.map((item) => {
                                return(
                                    <div key={item}>
                                        {item}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="basis-1/4 space-y-1 p-1">
                        Contact Us
                        <br></br>
                        <div>
                            Email: 
                        </div>
                        <div className='italic text-sm'>
                            admin@showbuoy.com
                        </div>
                        <div className='italic text-sm'>
                            services@webent.co.in
                        </div>
                        
                    </div>
                    <div className="basis-1/4 space-y-3 p-1">
                        Follow Us
                        <div className='flex justify-center'>
                            <a href="http://www.facebook.com" rel="noreferrer" target="_blank"> 
                            <FaFacebookSquare /> </a>
                        </div>
                        <div className='flex justify-center'>
                            <a href="https://www.youtube.com/channel/UCI1anQtKiL8l-d0UgAFaChw" rel="noreferrer" target="_blank"> 
                            <FaYoutube className=''/> </a>
                        </div>
                        <div className='flex justify-center'>
                            <a href="https://www.instagram.com/showbuoy_official/" rel="noreferrer" target="_blank"> 
                            <AiFillInstagram /> </a>
                        </div>
                        <div className='flex justify-center'>
                            <a href="http://www.twitter.com" rel="noreferrer" target="_blank"> 
                            <FaTwitter /> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer