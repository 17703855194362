import { useState } from "react";
import { v4 as uuid } from 'uuid';

interface Org {
    id:string,
    name: string,
    shortname: string,
}

interface Employee{
    supervisor:string,
    employee:string,
    reporters: Array<string>
}

const WorkflowCreateOrg = () => {

    const [org, setOrg] = useState<Org>({
                                            id:uuid(),
                                            name:'',
                                            shortname:''      
                                            });
    // Set Organization
    const updateOrg= (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setOrg({
            ...org,
            [event.currentTarget.name]: event.currentTarget.value
        })
    }

    // Add Employees
    const [employees, setEmployees] = useState<Array<Employee>>([{
                                                                    supervisor : '',
                                                                    employee : '',
                                                                    reporters: []       
                                                                }] as Array<Employee>)
                 
    const [currentEmployee, setCurrentEmployee] = useState<Employee>({ supervisor: "", employee: "", reporters: [] }) 

    const addEmployee = () => {
        console.log("clicked");
        setEmployees([...employees, { supervisor: "", employee: "", reporters: [] }]);
    }
    const removeEmployee = (index:number) => {
        console.log("Remove clicked : ", index);
        const list = [...employees];
        list.splice(index, 1);
        setEmployees(list);
    }
    const handleEmployeeChange = (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
        event.preventDefault();
        console.log("in employee");
        const { name, value } = event.currentTarget;
        const list = [...employees];
        list[index][name] = value;
        setEmployees(list);
        console.log("in employee 2");
      };



    const onSelectSupervisor = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        event.preventDefault();
        const { name, value } = event.currentTarget;
        console.log("Select : ", name, value );
        const list = [...employees];
        list[index][name] = value;
        setEmployees(list);
    }

    return(
        <div className="flex flex-col">
            <div className="flex flex-row items-center justify-center mt-10 font-semibold">
                Create Organization
            </div>
            <div className="flex flex-row items-center justify-center">
                <div>
                    <textarea className="w-96 border-2 border-rose-500 p-2 rounded"
                        placeholder="Enter Full name of the organization" name="name"
                        onChange={e => updateOrg(e)}>
                    </textarea>
                </div>
                <div>
                    <textarea className="m-3 border-2 border-rose-500 p-2 rounded"
                        placeholder="Enter short name" name="shortname" 
                        onChange={e => updateOrg(e)}>
                    </textarea>
                </div>
                <div>
                    <button className="rounded bg-lime-500 p-1">Submit</button>
                </div>
                <div className="m-3 w-96 text-right">
                    Organization : {JSON.stringify(org)}
                </div>
            </div>
            <hr className="text-xl"></hr>
            <div className="flex flex-row items-center justify-center mt-10 font-semibold">
                    Add Employees 
            </div>
            <div className="flex flex-row items-center justify-center">
                <div>
                    {
                        employees.map((item,i) => (
                            <div key={item.employee} className="flex flex-row items-center justify-center">
                                <textarea className="m-3 border-2 border-rose-500 p-2 rounded" 
                                    placeholder="Start Entering from Highest order" name="employee" 
                                    value={item.employee} onChange = {(e) => handleEmployeeChange(e,i)}> 
                                </textarea>
                                {
                                    (employees.length > 1) && (employees.length - 1 === i) &&
                                        <select className="border-2 border-rose-500 rounded-lg" 
                                                onChange={(e) => onSelectSupervisor(e,i)} name="supervisor">
                                            <option>select</option>        
                                            {employees.map((item) => (
                                                <option className="p-2">
                                                    {item.employee}
                                                </option>
                                            ))}
                                        </select>
                                }
                                {
                                    (employees.length !== 1) &&
                                    <button className="rounded bg-lime-500 p-1 m-1" 
                                                onClick={()=>removeEmployee(i)}>
                                        Remove Employee
                                    </button>
                                }
                                {
                                    (employees.length -1 === i) &&
                                        <button className="rounded bg-lime-500 p-1" 
                                                    onClick={()=>addEmployee()}>
                                            Add Employee
                                        </button>
                                }
                            </div>
                        ))
                    }
                    <div className="flex items-center justify-center ">
                        <button className="rounded bg-lime-500 p-1 m-5">
                            Submit
                        </button>
                    </div>
                </div>
                <div className="w-96 text-right">
                    {
                        JSON.stringify(employees)
                    }
                </div>
            </div>
        </div>
    )
}

export default WorkflowCreateOrg;