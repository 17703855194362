import axios from 'axios'
import IArtist from '../entities/IArtist'

let headers = {
    "Access-Control-Allow-Origin": "*",
    'Content-Type' : 'application/json',
    'Authorization': localStorage.getItem('token')! // ! mark is stating I know this will definetley have data
}

//  axios.defaults.baseURL = 'http://localhost:8080';
 axios.defaults.baseURL = 'https://surfserver.in';

 const uploadArtist = async(data: IArtist) => {

    // convert some data to string type
    const finalData:any = data;
    finalData.children = JSON.stringify(data.children);
    finalData.siblings = JSON.stringify(data.siblings);
    finalData.social = JSON.stringify(data.social);

    try{
         let res = await axios.post('/create/artist', data, {headers})

         return res.data.success

    }catch (error) {
        console.error(error);
    }
}

export { 
    uploadArtist,
 }