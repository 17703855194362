import axios from 'axios';
import {ILogin, IUser} from '../entities/IUser'
import IActor from '../entities/IActor';
import IArticleMetaData from '../entities/IArticleMetaData';
import IMovieReview from '../entities/IMovieReview';
import ICarouselImages from '../entities/ICarouselImages';
import { IArticleList } from '../entities/IList';
import { Iipl } from '../entities/Iipl';

let headers = {
    "Access-Control-Allow-Origin": "*",
    'Content-Type' : 'application/json'
}

//  axios.defaults.baseURL = 'http://localhost:8080';
 axios.defaults.baseURL = 'https://surfserver.in';

const getMovie = async () => {
    try {
      const response = await axios.get('/movie/0ac7cf7e-28c9-4877-b95d-47336cfeff66');
    //   console.log(response);
      return response
    } catch (error) {
      console.error(error);
    }
}

const signUp = async (user:IUser) => {

    let body = {
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        password: user.password
    }

    try{
        console.log("body : ", body);
       let res = await axios.post('/signup', body, {headers}); 
       console.log(res);
    } catch (error) {
        console.error(error);
    }
}

const signIn = async (user:ILogin) => {

    let body = {
        "username": user.email,
        "password": user.password
    }
    console.log(body);
    try{
       let res = await axios.post('/signin', body, {headers});
       console.log(res);
       return res.data
    } catch (error) {
        console.error(error);
    }
}

const uploadFile = async(data: any) => {

    headers['Content-Type'] = "multipart/form-data";
    headers['Authorization'] = localStorage.getItem('token')!;
    try{
         let res = await axios.post('/upload', data, {headers})
         return res.data.success;
    }catch (error) {
        console.error(error);
    }
}

const uploadMovie = async(data: any) => {
    headers['Content-Type'] = "application/json";
    headers['Authorization'] = localStorage.getItem('token')!;
    try{
         let res = await axios.post('/create/movie', data, {headers})
         return res.data.success
    }catch (error) {
        console.error(error);
    }
}

const uploadArtist = async(data: IActor) => {
    headers['Content-Type'] = "application/json";
    headers['Authorization'] = localStorage.getItem('token')!;
    try{
         let res = await axios.post('/create/artist', data, {headers})

         return res.data.success

    }catch (error) {
        console.error(error);
    }
}

const uploadArticle = async(data: IArticleMetaData) => {
    headers['Content-Type'] = "application/json";
    headers['Authorization'] = localStorage.getItem('token')!;
    try{
         let res = await axios.post('/create/article', data, {headers})
         return res.data.success

    }catch (error) {
        console.error(error);
    }
}

const updateArticle = async(data: IArticleList) => {
    headers['Content-Type'] = "application/json";
    headers['Authorization'] = localStorage.getItem('token')!;
    try{
         let res = await axios.put('/update/article', data, {headers})
         return res.data.success

    }catch (error) {
        console.error(error);
    }
}

const uploadReview = async(data: IMovieReview) => {
    headers['Content-Type'] = "application/json";
    headers['Authorization'] = localStorage.getItem('token')!;
    try{
         let res = await axios.post('/create/review', data, {headers})
         return res.data.success

    }catch (error) {
        console.error(error);
    }
}

const uploadCarousel = async(data: ICarouselImages) => {
    headers['Content-Type'] = "application/json";
    headers['Authorization'] = localStorage.getItem('token')!;
    console.log("In Carousel : ", data);

    try{
         let res = await axios.post('/create/carouselpics', data, {headers})
         return res.data.success

    }catch (error) {
        console.error(error);
    }
}

const updateCarousel = async(data: ICarouselImages) => {
    headers['Content-Type'] = "application/json";
    headers['Authorization'] = localStorage.getItem('token')!;
    console.log("update Carousel : ", data);
    try{
         let res = await axios.put('/update/carouselpics', data, {headers})
         return res.data.success
    }catch (error) {
        console.error(error);
    }
}

const deleteCarousel = async(data: ICarouselImages) => {
    headers['Content-Type'] = "application/json";
    headers['Authorization'] = localStorage.getItem('token')!;
    console.log("In Carousel : ", data);
    let param = '?id='+data.id
    try{
         let res = await axios.delete(`/delete/carouselpics${param}`, {headers})
         return res.data.success;
    }catch (error) {
        console.error(error);
    }
}

const deleteImage = async(path: string) => {
    headers['Content-Type'] = "application/json";
    headers['Authorization'] = localStorage.getItem('token')!;
    console.log("In Carousel : ", path);
    let param = '?path='+path
    try{
         let res = await axios.delete(`/delete/image${param}`, {headers})
         return res.data.success;
    }catch (error) {
        console.error(error);
    }
}

const updateSports = async(data: Array<Iipl>) => {
    headers['Content-Type'] = "application/json";
    headers['Authorization'] = localStorage.getItem('token')!;
    try{
         let res = await axios.put('/update/sports', data, {headers})
         return res.data.success

    }catch (error) {
        console.error(error);
    }
}

export { 
    getMovie,
    signUp,
    signIn,
    uploadFile,
    uploadMovie,
    uploadArtist,
    uploadArticle,
    updateArticle,
    uploadReview,
    uploadCarousel,
    updateCarousel,
    deleteCarousel,
    deleteImage,
    updateSports
 }
