import { Link } from "react-router-dom";
import { isAdmin } from "../state/userState";
import { useRecoilState } from "recoil";

const MobileMenu = (props:any) => {
    const [isAdminTrue, setAdminTrue] = useRecoilState(isAdmin);

    return(
        <div className="td:hidden font-serif text-gray-700">
            <div className="min-w-screen h-screen animated faster fixed  
                                left-0 top-0 flex justify-center items-center inset-0 z-50 
                                outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
                <div className="absolute bg-black opacity-40 inset-0 z-0"></div>
                <div className="w-full  max-w-lg p-5 relative mx-auto my-auto rounded-xl shadow-lg bg-cyan-800 ">
                
                <div className="">
                    <div className="text-center p-5 flex-auto justify-center text-amber-300">
                        <div>
                        <button className="text-4xl mb-6" onClick={props.updateMenu}> &#x2715; </button>
                        </div>
                        <div className="text-xl mb-6 p-2">
                            <button onClick={props.updateMenu}>
                                <nav>
                                    <Link to="/home">Home</Link>
                                </nav>
                            </button>
                        </div>
                        <div className="text-xl mb-6 p-2">
                            <button onClick={props.updateMenu}>
                                <nav>
                                    <Link to="/movies">Movies</Link>
                                </nav>
                            </button>
                        </div>
                        <div className="text-xl mb-6 p-2">
                            <nav>
                                <Link to="/sports">
                                    <button onClick={props.updateMenu}>Sports</button>
                                </Link>
                            </nav>
                        </div>
                        <div className="text-xl mb-6 p-2">
                            <nav>
                                <Link to="/artist">
                                    <button onClick={props.updateMenu}>Know Your STAR</button>
                                </Link>
                            </nav>
                        </div>

                        <div className="hidden text-xl mb-6 p-2">
                            <nav>
                                <Link to="/art">
                                    <button onClick={props.updateMenu}>Know Your ART</button>
                                </Link>
                            </nav>
                        </div>

                        {
                            (isAdminTrue) && 
                            <div className='grid grid-cols-2 mt-2 mb-2'>
                                <div className="place-self-center col-span-1 text-slate-100 "> 
                                    <button className="p-1 pl-3 pr-3 bg-yellow-900 rounded-md" 
                                        onClick={props.updateMenu}>
                                        <nav>
                                            <Link to="/edit" >Edit</Link>
                                        </nav>
                                    </button>
                                </div> 
                                <div className="place-self-center col-span-1 text-slate-100"> 
                                    <button className="p-1 pl-3 pr-3 bg-yellow-900 rounded-md" 
                                                onClick={props.updateMenu}>
                                        <nav>
                                            <Link to="/addnew" >Add</Link>
                                        </nav>
                                    </button>
                                </div> 
                            </div>
                        }
                        
                    </div>      
                </div>
                </div>
            </div>
        </div>
    )
}

export default MobileMenu;