import { useState } from "react";
import WorkflowCreateOrg from "./WorkflowCreateOrg";
import WorkflowPayments from "./WorkflowPayments";
import WorkflowCreateTasks from "./WorkflowCreateTasks";
import EmployeeTasks from "./EmployeeTasks";
import ITask from "./ITask";
import Assignments from "./Assignments";
import { isAdmin } from "../state/userState";
import { useRecoilState } from "recoil";

const workflows = [
    "All",
    "Ready",
    "In Progress",
    "Ready for Review",
    "Published"
]

const tasks: Array<ITask> = [
    {id:"",url:"",headline:"https://www.hindustantimes.com/entertainment/bollywood/jersey-attack-runway-34-double-trailer-double-impact-101650300072998.html",
        source:"",assingedto:"Srava",priority:"high", comment:"", status:workflows[0], deadline: new Date()},
    {id:"",url:"",headline:"Task 2",source:"",assingedto:"charu",priority:"low", comment:"", status:workflows[1], deadline: new Date()},
    {id:"",url:"",headline:"Task 3",source:"",assingedto:"agni",priority:"medium", comment:"", status:workflows[2], deadline: new Date()},
]

const priority = [
    "high",
    "medium",
    "low"
]

const levelp = [
    "charu",
    "agni",
    "murthy",
    "sravan",
    "venky",
    "sai chand"
]

const level1 = [
    {name:"srava", tasks:tasks},
    {name:"charu", tasks:tasks}
]

const roles = [
    "admin",
    "publisher",
    "user"
]

const tabs = [
    "My Tasks",
    "Create Organization",
    "Create Tasks",
    "Payments",
]

const Workflow = () => {
    const [isAdminTrue, setAdminTrue] = useRecoilState(isAdmin);
    const [status, setStatus] = useState(workflows[0]);
    const [assign, setAssign] = useState<string>();

    let username = "srava";
    // get the data using group by
    const filteredData = level1.filter((item) => (item.name === username));

    const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        setStatus(event.currentTarget.value);
    }

    const onAssign = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        setAssign(event.currentTarget.value);
    }

    const onSubmit = (index:number) => {
        console.log("assign : ", assign);
        console.log("index : ", index)
    }

    let role = "admin"

    const [currentTab, setCurrentTab] = useState(tabs[0])

    return(
        
        <div className="mx-10 my-3">
            {
                (isAdminTrue) && 
                <div>
                    <div className="flex flex-row items-center justify-center divide-x-4 m-10">
                        {
                            tabs.map((item) => (
                                <div className="p-5" key={item}>
                                    <button onClick={()=>setCurrentTab(item)}>{item}</button> 
                                </div>
                            ))
                        }
                    </div>
                    {
                        (currentTab === "My Tasks") && 
                            <div>
                                {
                                    (role === "admin") && (
                                        <div>
                                            <div className="text-center text-xl font-semibold mt-10 mb-3">
                                                Asignments
                                            </div>
                                            <Assignments tasks={tasks}/>
                                            <div className="flex flex-col">
                                                {
                                                    <EmployeeTasks status={status} tasks={tasks} assignedto={username}/>
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                    }
                    {
                        (currentTab === "Create Organization") && 
                            <WorkflowCreateOrg />
                    }
                    {
                        (currentTab === "Create Tasks") && 
                            <div>
                                {
                                    (role === "publisher" || role === "admin" ) && (
                                        <div>
                                            <div className="flex flex-row">
                                                <div className="basis-1/6 flex items-center justify-center">
                                                    Name
                                                </div>
                                                <div className="basis-2/6 flex items-center justify-center">
                                                    Tasks
                                                </div>
                                                <div className="basis-1/6 flex items-center justify-center">
                                                    Status: &nbsp; 
                                                    <select className="border-2 border-rose-500 rounded-lg" 
                                                            onChange={(e) => onSelect(e)} >
                                                        {workflows.map((item) => (
                                                            <option key={item} className="p-2">
                                                                {item}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="basis-1/6 flex items-center justify-center">
                                                    Deadline
                                                </div>
                                                <div className="basis-1/6 flex items-center justify-center">
                                                    Change Status
                                                </div>
                                        </div>
                                        {/**
                                         * Data
                                         */}
                                        <div className="flex flex-col">
                                            {
                                                <EmployeeTasks status={status} tasks={tasks} assignedto={username}/>
                                            }
                                        </div>
                                        </div>
                                    )
                                }
                                <WorkflowCreateTasks />
                            </div>
                    }
                    {
                        (currentTab === "Payments") && 
                            <WorkflowPayments />
                    }
                </div>
            }
            {
                (!isAdminTrue) && 
                <div className="flex items-center justify-center">
                    {"UnAuthorized"}
                </div>
            }
        </div>
    )
}

export default Workflow