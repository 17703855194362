import { useState } from "react"
import ITask from "./ITask"

const levelp = [
    "charu",
    "agni",
    "murthy",
    "sravan",
    "venky",
    "sai chand"
]

const workflows = [
    "All",
    "Ready",
    "In Progress",
    "Ready for Review",
    "Published"
]

const priority = [
    "high",
    "medium",
    "low"
]

const CreateTaskCard = () => {

    const [task, setTask] = useState({} as ITask)

    const onAssign = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        setTask({
            ...task,
            [event.currentTarget.name] : event.currentTarget.value
        })
    }

    const onSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        //setStatus(event.currentTarget.value);
    }

    return(
        <div>
            <div className="flex flex-row items-center justify-center ">
                <textarea className="m-3 border-2 border-rose-500 p-2 rounded"
                    placeholder="id" name="id"  
                    disabled>
                </textarea>
                <textarea className="m-3 border-2 border-rose-500 p-2 rounded"
                    placeholder="url" name="url"  
                    >
                </textarea>
                <textarea className="m-3 border-2 border-rose-500 p-2 rounded"
                    placeholder="headline" name="headline"  
                    >
                </textarea>
                <textarea className="m-3 border-2 border-rose-500 p-2 rounded"
                    placeholder="source" name="source"  
                    >
                </textarea>
                <div className="p-3">
                    Assign To:&nbsp; 
                    <select className="border-2 border-rose-500 rounded-lg" 
                            onChange={(e) => onAssign(e)} >
                        <option>select</option>
                        {levelp.map((item) => (
                                <option className="p-2">
                                    {item}
                                </option>
                            )
                        )}
                    </select>
                </div>
                <div>
                    Status:&nbsp; 
                        <select className="border-2 border-rose-500 rounded-lg" 
                                onChange={(e) => onSelect(e)} >
                            {workflows.map((item) => (
                                <option key={item} className="p-2">
                                    {item}
                                </option>
                            ))}
                        </select>
                </div>
                <div>
                &nbsp; Priority:&nbsp; 
                        <select className="border-2 border-rose-500 rounded-lg" 
                                onChange={(e) => onSelect(e)} >
                            <option>select</option>
                            {priority.map((item) => (
                                <option key={item} className="p-2">
                                    {item}
                                </option>
                            ))}
                        </select>
                </div>
            </div>
            <div className="flex items-center justify-center m-5">
                Deadline:&nbsp;  
                <div className ="datepicker relative form-floating xl:w-96">
                    <input type="date"
                        className ="form-control block w-full px-3 py-1.5 text-base 
                        font-normal text-gray-700 bg-white bg-clip-padding border-2 
                        border-solid border-rose-500 rounded transition ease-in-out 
                        m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 
                        focus:outline-none" name='ottreleasedate' 
                    placeholder="Select a date" />
                </div>
            </div>
            <div className="p-3 flex items-center justify-center m-5">
                <button className="rounded-lg bg-lime-500 p-2">
                    Submit
                </button>
            </div>
        </div>
    )
}

export default CreateTaskCard