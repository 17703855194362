import {FaUserAlt} from 'react-icons/fa'
import {loggedInState} from "../state/userState"
import { useRecoilState } from "recoil";
import {isAdmin} from '../state/userState'

const UserProfileCard = () => {

    const [loggedIn, setLoggedIn] = useRecoilState(loggedInState);
    const [isAdminTrue, setAdminTrue] = useRecoilState(isAdmin);
    
    const onClickLogout = () => {
        setLoggedIn(false)
        localStorage.removeItem('token');
        localStorage.removeItem('admin');
        setAdminTrue(false);
    }

    return(
        <div>
            <div className='grid grid-flow-row place-self-center'>
                <ul>
                    <li className="group relative dropdown  px-4
                                hover:text-purple-700 cursor-pointer
                                 tracking-wide">
                        <FaUserAlt className=" " />
                        <div className="group-hover:block dropdown-menu rounded-xl absolute -left-3 top-4 hidden">
                            <ul className=" bg-slate-500 text-red-200 rounded-xl shadow px-3">
                                    <li className="py-1">
                                        <div className="block 
                                                hover:text-yellow-300 cursor-pointer" >Profile</div></li>
                                    <li className="py-1">
                                        <div className="block 
                                            hover:text-yellow-300 cursor-pointer" >Settings</div></li>
                                    
                                    <li className="py-1">
                                        <div className="block 
                                            hover:text-yellow-300 cursor-pointer" onClick={onClickLogout}>Logout</div></li>
                                </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default UserProfileCard;