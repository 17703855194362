import {GiHamburgerMenu} from 'react-icons/gi'
import {GoSearch} from 'react-icons/go'
import { useState } from "react";
import MobileMenu from './MobileMenu';
import Login from '../forms/Login';
import { Link } from "react-router-dom";
import logo from '../logo/SHOWbuoy_logo.png';
import {loggedInState} from "../state/userState";
import { useRecoilState } from "recoil";
import UserProfileCard from '../cards/UserProfileCard';

const MobileHeader = () => {
    const [displaySearch, setDisplaySearch] = useState(false);
    const [loggedIn, setLoggedIn] = useRecoilState(loggedInState);

    const [showMenu, setShowMenu] = useState(false);

    const [loginClicked, setLoginClicked] = useState(false);

    const updateMenu = () => {setShowMenu(!showMenu)}
    const updateLoginClicked = () => {setLoginClicked(!loginClicked)}

    return(
        <div className="td:hidden p-1 bg-slate-800 text-gray-300">
            {
                (!displaySearch) && 
                    <div className='grid grid-cols-5 grid-flow-cols m-1'>
                        <div className='col-span-1 place-self-center'>
                            <button>
                                <GiHamburgerMenu size='30' onClick={ () => { setShowMenu(true) }}/> 
                            </button>
                        </div>

                        <div className='col-span-2 place-self-center brightness-150'>
                            <button>
                                <nav>
                                    <Link to="/home">
                                        <img className="rounded h-16 w-full" src={logo} alt=""/>
                                    </Link>
                                </nav>
                            </button>
                        </div>

                        <div className='col-span-1 place-self-center'>
                            <button> 
                                {!displaySearch && <GoSearch size='25' onClick={ () => { setDisplaySearch(true) } } /> } 
                            </button>
                        </div>

                        <div className='col-span-1 place-self-center border-2 p-2 rounded-md'>
                            {
                                (!loggedIn) &&
                                    <button onClick={updateLoginClicked}>Log In
                                    </button>
                            }
                            {
                                (loggedIn) && 
                                <button className=" place-self-center ">
                                    <UserProfileCard/>
                                </button>
                            }
                        </div>
                    </div>
            }
            {
                (displaySearch) && 
                        <div className='grid grid-cols-5 p-3 gap-2'>

                            <div className='col-span-3 place-self-center'>
                                <input type="search" className="h-12 w-full text-xl p-2 rounded-md"  />
                            </div>

                            <div className='col-span-2 place-self-center'>
                                <button type="reset" className='text-xl border-2 p-1 rounded-md' 
                                                        onClick={()=>setDisplaySearch(false)}>        
                                                        Go back</button>
                            </div>  
                        </div>
            }

            {
                (showMenu) && <MobileMenu  updateMenu={updateMenu}/>
            }  

            {
                loginClicked && <Login updateLoginClicked= {updateLoginClicked}/>
            }    
        </div>
    )
}

export default MobileHeader;