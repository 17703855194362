import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import NewsComponent from "../components/NewsComponent";
import IArticleMetaData from '../entities/IArticleMetaData';
import {useRecoilState} from "recoil";
import {articlesState} from "../state/recoilBootstrapState";
import MetaData from './MetaData'

const MobileNews = () => {
    const {id} = useParams();
    //window.scrollTo(0, 0)

    const [articlesList, setArticlesList] = useRecoilState(articlesState);
    const [currentArticle, setCurrentArticle] = useState<IArticleMetaData>();

    let headlines;
    let img;

    if(currentArticle !== undefined)
    {
        headlines = currentArticle!.headlines.length > 200 ? (currentArticle!.headlines.slice(0, 200) + "...") : currentArticle!.headlines ; 
        img = "https://surfserver.in" + currentArticle!.img;      
    }

    useEffect(() => {
        //setAsync(id!);
        const setAsync = async(id:string) => {
            const articles = articlesList.filter((item) => item.id === id)
            setCurrentArticle(articles[0])
        }
        setAsync(id!);

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    },[id,articlesList]);// Ignore warning

    return(
        <div>
            {
               (currentArticle) && (
                    <MetaData title={headlines} img={img} type="article"
                                headline={currentArticle.body} tags={currentArticle.tags}
                                publishedtime = "Some time" />
               ) 
            }
            <div className="flex flex-col bg-gray-200">
                <div className="flex justify-center">
                {
                        (currentArticle) && (
                            <div>
                                <div className="flex mb-3 drop-shadow-xl justify-center ">
                                    <img src={img}
                                            alt="aji" className="object-contain rounded-xl" />
                                </div>
                                
                                <div className=" bg-gray-700 rounded-xl p-3 text-white shadow-lg shadow-cyan-500/50">
                                    <article>
                                        <h2 className="text-lg text-center">{headlines}</h2>
                                        <br></br>
                                        <p className="text-sm p-3 whitespace-pre-wrap">
                                            {currentArticle.body}
                                        </p>
                                    </article>
                                    <hr></hr>
                                    <p className="text-right">
                                        <a href={currentArticle.href} rel="noreferrer" target="_blank" > &copy; {currentArticle?.source} </a>
                                    </p>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div>
                    <NewsComponent type="news"/>
                </div>
            </div>
        </div>
    )
}

export default MobileNews;