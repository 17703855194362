import { atom, selector } from "recoil";
import {getAllArtistsMetaData, getAllMoviesMetaData, 
        getAllArticles, getCarouselImages,
        getTopMovies, getReviewsData,
      getAllArtists} from "../client/getData";
import {IMovieList, IArtistList } from "../entities/IList";
import { Iipl } from "../entities/Iipl";
import ICarouselImages from "../entities/ICarouselImages";
import IMovieReview from "../entities/IMovieReview";
import IArticleMetaData from "../entities/IArticleMetaData";


const carouselsState = atom({
  key: 'carousels',
  default: [] as ICarouselImages[],
})

const reviewsState = atom({
  key: 'reviews',
  default: [] as IMovieReview[],
})

const articlesState = atom({
  key: 'articlesState',
  default: [] as IArticleMetaData[],
})

const pointsState = atom({
  key: 'pointsState',
  default: [] as Iipl[],
})

const movies = atom({
    key: 'movies',
    default: [] as IMovieList[],
  });

const artists = atom({
    key: 'artists',
    default: [] as IArtistList[],
  });

const articles = atom({
    key: 'articles',
    default: [] as IArtistList[],
  });

const sportsTable = atom({
    key: 'sportsTable',
    default: [] as Iipl[],
  });

const allMoviesMetaState = selector({
    key: 'allMoviesMetaState',
    get: async ({get}) => {
      return await getAllMoviesMetaData();
    },
  });
//topMoviesList
const topMoviesList = selector({
  key: 'topMoviesList',
  get: async ({get}) => {
    return await getTopMovies();
  },
});

const allArtistsMetaState = selector({
    key: 'allArtistsMetaState',
    get: async ({get}) => {
      return await getAllArtistsMetaData();
    },
  });

const allArtistsList = selector({
    key: 'allArtistsList',
    get: async ({get}) => {
      return await getAllArtists();
    },
  });


const allArticlesState = selector({
  key: 'allArticlesState',
  get: async ({get}) => {
    return await getAllArticles();
  },
});

const allCarouselState = selector({
  key: 'allCarouselState',
  get: async ({get}) => {
    return await getCarouselImages();
  },
});

const allReviewsState = selector({
  key: 'allReviewsState',
  get: async ({get}) => {
    return await getReviewsData();
  },
});

  export {
    carouselsState,
    reviewsState,
    articlesState,
    pointsState,
    movies,
    allMoviesMetaState,
    topMoviesList,
    artists,
    allArtistsMetaState,
    allArtistsList,
    allArticlesState,
    articles,
    allCarouselState,
    allReviewsState,
    sportsTable
  }