import {useRecoilValue} from "recoil";
import {allArticlesState} from "../state/recoilBootstrapState"
import { IArticleList } from "../entities/IList";
import { useState } from "react";
import { updateArticle } from "../client/apiCall";

const EditArticle = () => {
   
    const [currentItem, setCurrentItem] = useState<IArticleList>({
                                                                        id:'',
                                                                        headlines:'',
                                                                        href:'',
                                                                        body:'',
                                                                        img:'',
                                                                        tags:'',
                                                                        source:''
                                                                    });

    const articlesList = useRecoilValue(allArticlesState);

    console.log("articlesList : ", articlesList);
    const [editClicked, setEditClicked] = useState(false);
    const higherThanMaxLength = articlesList?.length! + 1;
    const [index, setIndex] = useState<number>(higherThanMaxLength);

    const onClickEdit = (item:IArticleList,i:number) =>{
        console.log("number : ", i);
        setIndex(i);
        setEditClicked(true);
        setCurrentItem(item);
    }

    const updateArticleState= (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setCurrentItem({
            ...currentItem,
            [event.currentTarget.name]: event.currentTarget.value
        })
    }

    const onClickSave = async (item:IArticleList, i:number) =>{
        console.log("number : ", i);
        setIndex(higherThanMaxLength);
        setEditClicked(false);
        console.log("currentItem : ", currentItem);
        const success = await updateArticle(currentItem);
        if(success){
            alert('Succesfully updated')
        }
    }

    return(
        <div className="bg-gray-200 mt-3">
            <div className="grid grid-rows-1 place-items-center">
                <div>
                    {
                        articlesList?.map((item:IArticleList, i:number) => (
                            <div className="grid grid-flow-col gap-6 m-3 p-3">

                                    {
                                       (index === i) && (
                                            <textarea className="border-2 border-sky-500 h-64 w-64" name='headlines' 
                                                        onChange={e => updateArticleState(e)}>
                                                {item.headlines}
                                            </textarea>
                                       )
                                    }
                                    {
                                        (index !== i) && (
                                            <div className="w-64 border-2 border-sky-500 p-3">
                                                {item.headlines}
                                            </div>
                                       ) 
                                    }
                                    {
                                       (index === i) && (
                                            <textarea className="border-2 border-sky-500 h-64 w-96" name='body' 
                                                onChange={e => updateArticleState(e)}>
                                                {item.body}
                                            </textarea>
                                       )
                                    }
                                    {
                                        (index !== i) && (
                                            <div className="w-96 border-2 border-sky-500 p-3">
                                                {item.body}
                                            </div>
                                       ) 
                                    }

{
                                       (index === i) && (
                                            <textarea className="border-2 border-sky-500 h-64 w-64" name='tags' 
                                                onChange={e => updateArticleState(e)}>
                                                {item.tags}
                                            </textarea>
                                       )
                                    }
                                    {
                                        (index !== i) && (
                                            <div className="w-64 border-2 border-sky-500 p-3">
                                                {item.tags}
                                            </div>
                                       ) 
                                    }
                                
                                <div className=" rounded-xl place-self-center">
                                    <button onClick={() => onClickEdit(item, i)} 
                                            className="bg-amber-700 p-3 rounded-xl mr-2"> Edit </button>
                                    {
                                        (editClicked) && (index === i) && (
                                            <button onClick={() => onClickSave(item,i)}  
                                                    className="bg-amber-700 p-3 rounded-xl">Save</button>
                                        )
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default EditArticle;