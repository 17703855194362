import { Iipl } from '../entities/Iipl';

interface Props{
    data:Iipl
}

const PointCard = ({data}:Props) => {

    return(
        <div className="rounded-xl bg-gray-700 text-sm">
            <div className='grid grid-cols-8 grid-flow-col place-items-center gap-1'>
                <div className="mr-2">
                    {data.teamname}
                </div>
                <div>
                    {data.played}
                </div>
                <div>
                    {data.won}
                </div>
                <div>
                    {data.lost}
                </div>
                <div>
                    {data.tied}
                </div>
                <div>
                    {data.noresult}
                </div>
                <div>
                    {data.points}
                </div>
                <div>
                    {data.netrunrate}
                </div>
            </div>
        </div>
    )
}

export default PointCard;