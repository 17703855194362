import {useRecoilValue, useRecoilState} from "recoil";
import {articlesState} from "../state/recoilBootstrapState";
import NewsCard from "../cards/NewsCard";
import  IArticleMetaData from "../entities/IArticleMetaData";
import { Link } from "react-router-dom";

interface Props{
    type:string
}
const NewsComponent = ({type}:Props) => {
    // const articlesList = useRecoilValue(allArticlesState);
    const [articlesList, setArticlesList] = useRecoilState(articlesState);
   
    const sportTags = ["sports", "ipl","cricket"]

    const filteredList = articlesList?.filter((item) => (item.subtype === 'Sports'))
    let displayList;
    if(type === 'news') {
        displayList = articlesList?.filter((item) => (item.subtype === 'Movie'));
    }
    else displayList = filteredList

    return(
        <div>
            <div className="m:hidden flex flex-wrap justify-center gap-3 p-2">
                {
                    displayList?.map((item: IArticleMetaData, i) => {
                            let url = "/news/"+ item.headlines+"/"+item.id;
                            url = url.replace(/\s/g, '-')
                            return (
                                <div key={item.id}>
                                    <nav>
                                        <Link to={`${url}`} >
                                            <NewsCard  key={item.id} data={item} type={type}/>
                                        </Link>
                                    </nav> 
                                </div>
                            )
                    })
                }
            </div>
            <div className="td:hidden h-96 flex flex-wrap overflow-x-auto justify-center gap-3 p-2">
                {
                    displayList?.map((item: IArticleMetaData, i) => {
                            let url = "/news/"+ item.headlines+"/"+item.id;
                            url = url.replace(/\s/g, '-')
                            return (
                                <div key={item.id}>
                                    <nav>
                                        <Link to={`${url}`} >
                                            <NewsCard  key={item.id} data={item} type={type}/>
                                        </Link>
                                    </nav> 
                                </div>
                            )
                    })
                }
            </div>
        </div>
        
    )
}

export default NewsComponent;