import {Leagues} from '../entities/constant'
import {ChangeEvent, useEffect, useState } from 'react';
import { getSportsListbyGroup } from '../client/getData';
import { Iipl } from '../entities/Iipl';
import {updateSports} from '../client/apiCall'
import SuccessCard from '../cards/SuccessCard';

const EditSports = () => {

    const [leagueSelected, setLeagueSelected] = useState<string>();
    const [updated, setUpdated] = useState(false);

    const addSelectedValue = async(event: ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        setLeagueSelected(event.target.value);
    }
    
    const [updateData, setUpdateDate] = useState<Array<Iipl>>();

    const setData = async () => {
        const data = await getSportsListbyGroup('ipl');
        setUpdateDate(data!);
    }

    const updateSportDetails = (event: React.FormEvent<HTMLInputElement>, i:number): void => {
        let newArray = [...updateData!];
        newArray[i][event.currentTarget.name] = event.currentTarget.value;
        setUpdateDate(newArray);
    }

    const onClickUpdate = async() => {
       const success = await updateSports(updateData!)
       if(success) setUpdated(true)
    }

    useEffect(() => {
        setData();
    },[]);
    return(
        <div>
            {
                (!updated) && (
                    <div className='grid grid-rows-1 mt-10'>
                        <div className="w-80 grid place-self-center relative">
                            <select className="block appearance-none w-full bg-gray-200 border 
                                    border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded 
                                    leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                    id="grid-state" onChange={(e)=>addSelectedValue(e)}>
                                {
                                    Leagues.map((add)=>(
                                        <option key={add} >{add}</option>
                                    ))
                                }
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                            </div>
                        </div>
                        <div className="w-96 grid place-self-center text-base relative mt-10 justify-items-center ">
                            {
                                (updateData) && (
                                    <div className='grid gap-3'>
                                    {
                                        updateData.map((item,i) => (
                                            <div key={item.id} className='grid grid-flow-col'>
                                                <div className='border p-4 w-16 text-center'>
                                                    {item.teamname}
                                                </div>
                                                <input type="text"className='border w-16' placeholder='played' 
                                                        name='played' value={item.played} onChange={e => updateSportDetails(e,i)}/>
                                                <input type="text"className='border w-16' placeholder='won' 
                                                        name='won' value={item.won} onChange={e => updateSportDetails(e,i)}/>
                                                <input type="text"className='border w-16' placeholder='lost' 
                                                        name='lost' value={item.lost} onChange={e => updateSportDetails(e,i)}/>
                                                <input type="text"className='border w-16' placeholder='tied' 
                                                        name='tied' value={item.tied} onChange={e => updateSportDetails(e,i)}/>
                                                <input type="text"className='border w-16' placeholder='noresult' 
                                                        name='noresult' value={item.noresult} onChange={e => updateSportDetails(e,i)}/>
                                                <input type="text"className='border w-16' placeholder='points' 
                                                        name='points' value={item.points} onChange={e => updateSportDetails(e,i)}/>
                                                <input type="text"className='border w-16' placeholder='netrunrate' 
                                                        name='netrunrate' value={item.netrunrate} onChange={e => updateSportDetails(e,i)}/>
                                            </div>
                                        ))
                                    }
                                    </div>
                                )
                            }
                        </div>
                        <div className='grid place-self-center w-80 text-white text-center m-10 p-4 bg-amber-700 cursor-pointer'
                                onClick={() => onClickUpdate()} >
                            Update
                        </div>
                    </div>
                )
            }
            {
                (updated) && (
                    <SuccessCard />
                )
            }
        </div>
    )
}

export default EditSports