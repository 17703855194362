import PointsTable from '../components/PointsTable'
import NewsComponent from '../components/NewsComponent'
import {Leagues} from '../entities/constant'
import MetaData from './MetaData'

const Sports = () => {

    // Ideally We get sports news

    return(
        <div>
            <MetaData title="SHOWbuoy | Latest Cricket news" img="" type="page"
                        headline="Find all the latest news and articles on Criket and IPL" 
                        tags="sports, cricket, IPL, Mumbai Indians, Punjab Kings,
                            Delhi Capitals, Luknow Super Gaints, Kolkata knight riders,
                            Sunrisers Hyderabad, Royal Challengers Bangalore, Chennai Super Kings"
                            publishedtime = "Some time" 
            />
            <div className="m:hidden">
                <div className="flex flex-row text-center space-x-2 t:mx-2 d:mx-16 my-2">
                    <div className="basis-1/5 space-y-1 px-16">
                        {
                            Leagues.map((item) => (
                                <div key={item} className="text-center text-white bg-amber-700 rounded-xl">
                                    <button>{item}</button>
                                </div>
                            ))
                        }
                    </div>
                    <div className="basis-2/5 space-y-1">
                        {/* <div className='grid grid-flow-col space-x-2 text-center text-white bg-amber-700 rounded-xl relative'>
                            <div>
                                <button>
                                    CSK
                                </button>
                            </div>
                            <div>DC</div>
                            <div>GT</div>
                            <div>KKR</div>
                            <div>LSG</div>
                            <div>MI</div>
                            <div>PK</div>
                            <div>RCB</div>
                            <div>RR</div>
                            <div>SRH</div>
                        </div> */}
                        <div>
                            <NewsComponent type="sports"/>
                        </div>
                    </div>
                    <div className="basis-2/5">
                        <div className="max-h-96 bg-gray-700 rounded-xl 
                                    shadow-xl text-white overflow-y-auto d:scrollbar-hide">
                            <div className="p-3 space-y-4">
                                <PointsTable />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
)}

export default Sports