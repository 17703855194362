import React, {useState } from 'react';
import { v4 as uuid } from 'uuid';
import "react-datepicker/dist/react-datepicker.css";
import {uploadFile} from '../client/apiCall';
import {isNullOrEmpty} from "../validations/validate";
import {TiTickOutline} from 'react-icons/ti';
import SuccessCard from '../cards/SuccessCard';
import InfoCard from '../cards/InfoCard';
import { imagePath } from '../entities/constant';
import IArtist, {IArtistBasicDetails} from '../entities/IArtist';
import {uploadArtist} from '../client/artists'
import { searchNames } from '../helpers/search';
import DropDown from './DropDown';
import {useRecoilState} from 'recoil';
import {artists} from '../state/recoilBootstrapState'

const AddArtist = () => {
    const [artistList, setArtistList] = useRecoilState(artists);
    
    const [uploadClicked, setUploadClicked] = useState(false);
    const [artistUploadedSuccessfully, SetArtistUploadedSuccessfully] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false); 

    const [artist, setArtist] = useState<IArtist>({id:uuid(),
                                                    name:'',
                                                    alias:'',
                                                    role:'',
                                                    info:'',
                                                    dob: new Date(),
                                                    placeofbirth:'',
                                                    father: {} as IArtistBasicDetails,
                                                    mother: {} as IArtistBasicDetails,
                                                    spouce: {} as IArtistBasicDetails,
                                                    children: [] as Array<IArtistBasicDetails>,
                                                    siblings: [] as Array<IArtistBasicDetails>,
                                                    companies: '',
                                                    charities: '',
                                                    social: [] as Array<IArtistBasicDetails>,
                                                    imgpath : '',
                                                    networth: ''
                                                    } as IArtist);

    const updateArtist= (event: React.FormEvent<HTMLInputElement>): void => {
        setArtist({
            ...artist,
            [event.currentTarget.name]: event.currentTarget.value
        })
    }

    const setImgPath = (setImgPath) => {

        const val = imagePath + "/" +artist.id + "." + setImgPath;
        setArtist({
            ...artist,
            "imgpath": val
        })
    }
    
    const updateArtistInfo= (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setArtist({
            ...artist,
            [event.currentTarget.name]: event.currentTarget.value
        })
    }

    const [uploadImage, setUploadImage] = useState<File>();
    
    const handleImageFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (!event.currentTarget.files) return;
        const file: FileList = event.currentTarget.files;
        setUploadImage(file[0]);
        const size = file[0].name.split('.'); // returns png/jpeg
        const format = file[0].name.split('.')[size.length-1];
        setImgPath(format);
    }

    let formData = new FormData();
    const onClickUpload = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if(uploadImage === undefined)
        {
            alert("Choose a file to upload")
        }else{
            formData.append("name", artist.id!);
            formData.append("file",uploadImage!);

            const res = await uploadFile(formData);
            if(res) setUploadSuccess(true)

            setUploadClicked(true);
        }
    }
      

    // Code to search if actor already exists
    const [searchList, setSearchList] = useState([]);
    const [showFatherNameDD, setShowFatherNameDD] = useState(false);
    const [showMotherNameDD, setShowMotherNameDD] = useState(false);
    const [showSpouceNameDD, setShowSpouceNameDD] = useState(false);

    const onChange = async(event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const val = event.currentTarget.value;
        const name = event.currentTarget.name;
        const obj:IArtistBasicDetails = {name: event.currentTarget.value, id:''}

        setArtist({
            ...artist,
            [event.currentTarget.name]: obj
        })
        
        if(val.length > 0)
        {
            let filteredData = await searchNames(val, artistList);
            setSearchList(filteredData)
            if(filteredData?.length === 0 ) {
                //setShowFatherNameDD(false);
                // Evrything would be false
            }else{
                switch(name){
                    case 'father':
                        setShowFatherNameDD(true);
                        break;
                    case 'mother':
                        setShowMotherNameDD(true);
                        break;
                    case 'spouce':
                        setShowSpouceNameDD(true);
                        break;
                }
            }
        }else{
                setShowFatherNameDD(false);
                setShowMotherNameDD(false);
                setShowSpouceNameDD(false);
            }  
    }
    const onFatherNameSelectChange = (data) => {
        setArtist({
            ...artist,
            father: data
        })
        setShowFatherNameDD(false);
    }
    const onMotherNameSelectChange = (data) => {
        setArtist({
            ...artist,
            mother: data
        })
        setShowMotherNameDD(false);
    }
    const onSpouceNameSelectChange = (data) => {
        setArtist({
            ...artist,
            spouce: data
        })
        setShowSpouceNameDD(false);
    }

    //  On Search of Artist name
    const [showArtistNameDD, setShowArtistNameDD] = useState(false);

    const onSearchName = async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const val = event.currentTarget.value;
        setArtist({
            ...artist,
            [event.currentTarget.name]: event.currentTarget.value
        })

        let filteredData = await searchNames(val, artistList);
        if(val.length > 0)
        {
            let filteredData = await searchNames(val, artistList);
            setSearchList(filteredData)
            if(filteredData?.length !== 0 ) {
                setShowArtistNameDD(true);
            }else{
                setShowArtistNameDD(false);
            }
        }else{
            setShowArtistNameDD(false);
            }

        setSearchList(filteredData)
    }

    const onArtistNameSelectChange = (data:any) => {
        setArtist({
            ...artist,
            name: data.name
        })
        setShowArtistNameDD(false);
    }

    // Selecting multiple people
    
    const [siblingData, setSiblingData] = useState<Array<IArtistBasicDetails>>([] as Array<IArtistBasicDetails>);
    const [showSiblingNameDD, setShowSiblingNameDD] = useState(false);
    const [newSibling, setNewSibling] = useState<IArtistBasicDetails>({} as IArtistBasicDetails);

    const onChangeAddMultiple = async(event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const val = event.currentTarget.value;

        setNewSibling({
            ...newSibling,
            name: event.currentTarget.value,
            id:''
        })
        
        if(val.length > 0)
        {
            let filteredData = await searchNames(val, artistList);
            setSearchList(filteredData)
            if(filteredData?.length !== 0 ) {
                setShowSiblingNameDD(true);
            }else{
                setShowSiblingNameDD(false);
            }
        }else{
                setShowSiblingNameDD(false);
            }  
    }
    const onSiblingAdd = () => {
        setSiblingData(siblingData => [...siblingData, newSibling])

        setArtist({
            ...artist,
            siblings: siblingData
        })
    }
    const onSiblingRemove = (obj) => {

        let removeFiltered= siblingData?.filter((item) => (item.name !== obj.name))

        setSiblingData(removeFiltered)

        setArtist({
            ...artist,
            siblings: siblingData
        })
    }

    const onSiblingNameSelectChange = (data:IArtistBasicDetails) => {

        setSiblingData(siblingData => [...siblingData, data]) // Pushing new element to list

        setArtist({
            ...artist,
            siblings: siblingData
        })
        setShowSiblingNameDD(false);
    }

    // Set Children Data
    // Selecting multiple people
    
    const [childrenData, setChildrenData] = useState<Array<IArtistBasicDetails>>([] as Array<IArtistBasicDetails>);
    const [showChildrenNameDD, setShowChildrenNameDD] = useState(false);
    const [newChildren, setNewChildren] = useState<IArtistBasicDetails>({} as IArtistBasicDetails);

    const onChangeChildrenAddMultiple = async(event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const val = event.currentTarget.value;

        setNewChildren({
            ...newChildren,
            name: event.currentTarget.value,
            id:''
        })
        
        if(val.length > 0)
        {
            let filteredData = await searchNames(val, artistList);
            setSearchList(filteredData)
            if(filteredData?.length !== 0 ) {
                setShowChildrenNameDD(true);
            }else{
                setShowChildrenNameDD(false);
            }
        }else{
                setShowChildrenNameDD(false);
            }  
    }

    const onChildrenAdd = () => {
        setChildrenData(childrenData => [...childrenData, newChildren])

        setArtist({
            ...artist,
            children: childrenData
        })
    }

    const onChildrenRemove = (obj) => {

        let removeFiltered= childrenData?.filter((item) => (item.name !== obj.name))

        setChildrenData(removeFiltered)

        setArtist({
            ...artist,
            children: childrenData
        })
    }

    const onChildrenNameSelectChange = (data:IArtistBasicDetails) => {

        setChildrenData(childrenData => [...childrenData, data]) // Pushing new element to list

        setArtist({
            ...artist,
            children: childrenData
        })
        setShowChildrenNameDD(false);
    }

    // Social Ids
    const [socialData, setSocialData] = useState<Array<IArtistBasicDetails>>([] as Array<IArtistBasicDetails>);
    const [twitterData, setTwitterData] = useState<IArtistBasicDetails>({} as IArtistBasicDetails);
    const [instagramData, setInstagramData] = useState<IArtistBasicDetails>({} as IArtistBasicDetails);

    const updateTwitterData = async(event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setTwitterData({
            ...twitterData,
            name: "Twitter",
            id: event.currentTarget.value
        })
    }
    const updateInstagramData = async(event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setInstagramData({
            ...instagramData,
            name: "Instagram",
            id: event.currentTarget.value
        })

    }

    const updateSocialIds = () => {
        socialData.push(twitterData);
        socialData.push(instagramData);

        setArtist({
            ...artist,
            social: socialData
        })
    }

    const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        updateSocialIds();
        if(isNullOrEmpty(artist.name))
        {
            let success = await uploadArtist(artist);
            if(success) SetArtistUploadedSuccessfully(true);
        }
    }

    return(
        <div className="Artist mt-4">
            {
                (!artistUploadedSuccessfully) && 
                <form onSubmit={ e => handleOnSubmit(e) }>
                    <div className="grid grid-flow-row gap-2 place-items-center font-serif 
                                        bg-gradient-to-r from-pink-500 to-yellow-500
                                    text-gray-700 p-2">
                        <div className="font-bold text-xl mt-5">
                            Artist Id
                        </div>
                        <div>
                            <input disabled className="h-8 w-80 rounded-md mr-3 p-2 text-black" name='id' 
                                    type="text" value={artist.id}/>
                        </div>           
                        <div className="font-bold text-xl mt-5">
                            Artist Photo <InfoCard information="(make sure it is vertical)" />
                        </div>
                        <div className="">
                            <input type="file" className="w-60 rounded-md bg-white border-solid 
                                    border-4 border-yellow-500 " name="file" onChange = {handleImageFile} />
                            {
                                (uploadClicked && uploadSuccess) && 
                                    <span>
                                        <button className=" p-1 ml-3 pl-3 pr-3  
                                            bg-yellow-900 rounded-md text-white" >
                                                        <TiTickOutline />  
                                        </button>
                                        {/* <button>
                                            <MdCancelPresentation className=''/>
                                        </button> */}
                                    </span>
                                
                            }
                            {
                                (!uploadClicked) && 
                                    <span>
                                        <button className="p-1 ml-3 pl-3 pr-3  
                                            bg-yellow-900 rounded-md text-white" onClick={onClickUpload}>
                                                    Upload
                                        </button>
                                    </span>
                            }
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Artist Name
                        </div>
                        <div>
                        <input className="h-8 w-80 rounded-md mr-3 p-2 text-black place-self-center" 
                                type="text" name='name' value={artist.name} onChange={e => onSearchName(e)} placeholder="Artist Name"/>
                            {
                                (showArtistNameDD) && 
                                <div>
                                    <DropDown optionList={searchList} onSelectChange={(e) => onArtistNameSelectChange(e)}/>
                                </div>
                            }
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Alias Name <InfoCard information="(eg: jr.NTR for - Nandamuri Tarak Ramarao)" /> 
                        </div>
                        <div>
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black place-self-center" 
                                type="text" name='alias'  onChange={e => updateArtist(e)} placeholder="Alias Name"/>
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Role <InfoCard information="(eg: Actor, Director, Female Lead)" /> 
                        </div>
                        <div>
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black place-self-center" 
                                type="text" name='role'  onChange={e => updateArtist(e)} placeholder="Actor"/>
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Info
                        </div>
                        <div>
                            <textarea className="w-80 h-24 min-w-full max-w-2xl rounded-md mr-3 p-2" 
                                    placeholder="Predominantly works in ..." name='info' onChange={e => updateArtistInfo(e)}>
                                
                            </textarea>
                        </div>
                        {/**
                         * ****************************************
                         * Willbe populated default
                         */}
                        <div className="font-bold text-xl mt-5">
                            Date of Birth (mm/dd/yyyy)
                        </div>
                        <div className='place-self-center'>
                            <div className ="datepicker relative form-floating mb-3 xl:w-96">
                                <input type="date"
                                    className ="form-control block w-full px-3 py-1.5 text-base 
                                    font-normal text-gray-700 bg-white bg-clip-padding border 
                                    border-solid border-gray-300 rounded transition ease-in-out 
                                    m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 
                                    focus:outline-none" name='dob' onChange={e => updateArtist(e)}
                                placeholder="Select a date" />
                            </div>
                        </div>
                    
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Place of Birth
                        </div>
                        <div>
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                            onChange={e => updateArtist(e)} name='placeofbirth' type="text" />
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Father Name
                        </div>
                        <div>
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                value={artist.father.name} onChange={e => onChange(e)} name="father" type="text" />
                            {
                                (showFatherNameDD) && 
                                    <div>
                                        <DropDown optionList={searchList} onSelectChange={(e) => onFatherNameSelectChange(e)}/>
                                    </div>
                            }
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Mother Name 
                        </div>
                        <div className="">
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                            value={artist.mother.name} onChange={e => onChange(e)} name="mother" type="text" />
                            {
                                (showMotherNameDD) && 
                                <div>
                                    <DropDown optionList={searchList} onSelectChange={(e) => onMotherNameSelectChange(e)}/>
                                </div>
                            }
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Sibling Name 
                        </div>
                        <div className="">
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                            onChange={e => onChangeAddMultiple(e)}  type="text" /> 
                            <button type='button' className='bg-yellow-900 rounded-md text-white px-2' onClick={onSiblingAdd}>Add</button>
                            {
                                (showSiblingNameDD) && 
                                    <div>
                                        <DropDown optionList={searchList} onSelectChange={(e) => onSiblingNameSelectChange(e)}/>
                                    </div>
                            }
                        </div>
                        <div>
                            {
                                (siblingData.length !== 0) && 
                                        siblingData.map((item)=>{
                                                return(
                                                    <div>
                                                        {item.name}: {item.id} 
                                                        <button className='bg-yellow-900 rounded-md 
                                                        text-white px-1 ml-2 text-sm' onClick={()=>onSiblingRemove(item)}>x</button>
                                                    </div>
                                                )
                                            })
                                    
                            }
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Spouce
                        </div>
                        <div className="">
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                            value={artist.spouce.name} onChange={e => onChange(e)} name="spouce" type="text" />
                            {
                                (showSpouceNameDD) && 
                                <div>
                                    <DropDown optionList={searchList} onSelectChange={(e) => onSpouceNameSelectChange(e)}/>
                                </div>
                            }
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Children 
                        </div>
                        <div className="">
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                                    onChange={e => onChangeChildrenAddMultiple(e)} name="children" type="text" />
                            <button type='button' className='bg-yellow-900 rounded-md text-white px-2' onClick={onChildrenAdd}>Add</button>
                            {
                                (showChildrenNameDD) && 
                                    <div>
                                        <DropDown optionList={searchList} onSelectChange={(e) => onChildrenNameSelectChange(e)}/>
                                    </div>
                            }
                        </div>
                        <div>
                            {
                                (childrenData.length !== 0) && 
                                childrenData.map((item)=>{
                                                return(
                                                    <div>
                                                        {item.name}: {item.id} 
                                                        <button className='bg-yellow-900 rounded-md 
                                                        text-white px-1 ml-2 text-sm' onClick={()=>onChildrenRemove(item)}>x</button>
                                                    </div>
                                                )
                                            })
                                    
                            }
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Owned Companies <InfoCard information="(If associated with seperated by ,)" /> 
                        </div>
                        <div className="">
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                            onChange={e => updateArtist(e)} name="companies" type="text" />
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Charities <InfoCard information="(If associated with seperated by ,)" /> 
                        </div>
                        <div className="">
                            <input className="h-8 w-80 rounded-md mr-3 p-2 text-black" 
                            onChange={e => updateArtist(e)} name="charities" type="text" />
                        </div>
                        {/**
                         * ****************************************
                         */}
                        <div className="font-bold text-xl mt-5">
                            Twitter Id &nbsp;
                            <input className="h-8 rounded-md mr-3 p-2 text-black" 
                            onChange={e => updateTwitterData(e)} name="twitter" type="text" />
                        </div>
                        <div className="font-bold text-xl mt-5">
                            Instagram Id &nbsp;
                            <input className="h-8 rounded-md mr-3 p-2 text-black" 
                            onChange={e => updateInstagramData(e)} name="instagram" type="text" />
                        </div>
                        {/**
                         * ****************************************
                         */}
                        {
                        (!uploadClicked) && 
                            <div>
                                <div className="grid grid-rows-1 mt-2">
                                    <div className="bg-red-900 text-center py-2 lg:px-4 animate-pulse rounded-lg">
                                        <div className="p-2 bg-red-800 items-center text-indigo-100 leading-none 
                                                lg:rounded-full flex lg:inline-flex" role="alert">
                                            <span className="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">New</span>
                                            <span className="font-semibold mr-2 text-center flex-auto">
                                                (Please click on upload button in the top of the page to upload image)</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {/**
                         * ****************************************
                         */}

                        <div className="text-slate-100 mb-10 mt-5">
                            <button className="p-1 pl-3 pr-3  bg-yellow-900 rounded-md mr-10">Cancel</button>
                            {
                                uploadClicked && 
                                <button type="submit" className="p-1 pl-3 pr-3  bg-yellow-900 rounded-md">Submit</button>
                            }
                            {
                                (!uploadClicked) && 
                                <button type="submit" className="p-1 pl-3 pr-3 bg-red-500 rounded-md" disabled>Submit</button>
                            }
                        </div>

                    </div>
                </form>
            }
            {
                (artistUploadedSuccessfully) && 
                        <div>
                            <SuccessCard />
                        </div>
            }
            <div>
            </div>
        </div>
    )
}

export default AddArtist;