import UrlCard from "../cards/UrlCard";
import CarouselCard from "../cards/CarouselCard";
import {useRecoilValue} from "recoil";
import {allCarouselState} from "../state/recoilBootstrapState";
import ReviewComponent from "../components/ReviewComponent";
import PointsTable from "../components/PointsTable";
import NewsComponent from "../components/NewsComponent";
import logo from '../logo/SHOWbuoy_logo.png'
import MetaData from './MetaData'

const Home = () => {
    // There should be atleast one Value call to hold of a timeout
    const carouselList = useRecoilValue(allCarouselState)!; // Don't think just keep this here

    return(
        <div className="Home">
            <MetaData title="SHOWbuoy | Indian Cinema" img={logo} type="page"
                        headline="Find all the news and articles on Criket, IPL and latest Movies in Theaters and OTT" 
                        tags="movies, telugu movies, hindi movies, tamil movies, kannada movies, 
                                malayalam movies, top movies, Netflix, Prime Video, Sun Nxt, Aha Video,
                                Top Indian movies in Netflix, Disney + Hotstar, Youtube, Jio Movies, Zee 5,
                                Top Telugu Movies, Top Hindi Movies, Top Tamil Movies, Top Malayalam Movies,
                                sports, cricket, IPL, Mumbai Indians, Punjab Kings,
                                Delhi Capitals, Luknow Super Gaints, Kolkata knight riders,
                                Sunrisers Hyderabad, Royal Challengers Bangalore, Chennai Super Kings"
                            publishedtime = "Some time" 
            />
            <div className="m:hidden">
                <div className="flex flex-row space-x-2 t:mx-2 d:mx-16 my-2">
                    <div className="basis-3/5 space-y-1">
                        <div className="flex justify-center">
                            {
                                <CarouselCard data={carouselList}/>
                            }
                        </div>
                        <div className="text-center text-white text-lg bg-amber-700 rounded-xl relative">
                            <h1>Brief - Just the Entertainment you need</h1>
                            {/* <a href="/news" className="absolute right-0 bottom-1 pr-2 text-sm">show more</a> */}
                        </div>
                        <div className="h-1/2">
                            <NewsComponent type="news"/>
                        </div>
                    </div>
                    <div className="text-white basis-2/5 space-y-4">
                        {/*
                        * Rating Card
                        */}
                        <div className="text-center text-lg bg-amber-700 rounded-xl relative">
                            <h1>Trending Movies</h1>
                            <a href="/movies" className="absolute right-0 bottom-1 pr-2 text-sm">show more</a>
                        </div>
                        {/* <div className="text-center text-lg text-black">
                            <input type='input' placeholder="Search Movies" className="border-2 rounded-xl px-2"/>
                            <button className="border-2 rounded-xl px-2 ml-2 bg-amber-700 text-white">Search</button>
                        </div> */}
                        <div className="h-96 space-y-2 bg-gray-700 
                                    shadow-xl text-white overflow-y-auto d:scrollbar-hide rounded-xl">
                            <ReviewComponent />
                        </div>
                        
                        <div className="text-center text-lg bg-amber-700 rounded-xl relative">
                            <h1>Points Table</h1> 
                            <a href="/sports" className="absolute right-0 bottom-1 pr-2 text-sm">show more</a>
                        </div>
                        
                        <div className="max-h-96 mt-3 space-y-2 bg-gray-700 rounded-xl 
                                    shadow-xl text-white overflow-y-auto d:scrollbar-hide">
                            <div className="p-3 space-y-4">
                                <PointsTable />
                            </div>
                        </div>
                        <div className="text-center text-white text-lg bg-amber-700 rounded-xl relative">
                            <h1>Sports News</h1>
                            <a href="/sports" className="absolute right-0 bottom-1 pr-2 text-sm">show more</a>
                        </div>
                        <div className="h-[48rem] space-y-2 bg-gray-700 
                                    shadow-xl text-white overflow-y-auto d:scrollbar-hide rounded-xl">
                            <NewsComponent type="sports"/>
                        </div>
                        
                        {/* <div className="text-center text-lg bg-amber-700 rounded-xl relative">
                            <h1>Facinating Stories</h1>
                            <a href="/sports" className="absolute right-0 bottom-1 pr-2 text-sm">show more</a>
                        </div>
                        
                        <div className="h-96 mt-3 space-y-2 bg-gray-700 rounded-xl 
                                    shadow-xl text-white overflow-y-auto d:scrollbar-hide">
                            <div className="p-3 space-y-4">
                                <UrlCard />
                                <UrlCard />
                                <UrlCard />
                                <UrlCard />
                                <UrlCard /> 
                                <UrlCard />
                                <UrlCard />
                            </div>
                        </div> */}
                        {/* <div className="text-center text-lg bg-amber-700 rounded-xl relative">
                            <h1>Sports News</h1>
                            <a href="/news" className="absolute right-0 bottom-1 pr-2 text-sm">show more</a>
                        </div>
                        <div className="h-96 mt-3 space-y-2 bg-gray-700 rounded-xl 
                                    shadow-xl text-white overflow-y-auto d:scrollbar-hide">
                            <div className="p-3 space-y-4">
                                <UrlCard />
                                <UrlCard />
                                <UrlCard />
                                <UrlCard />
                                <UrlCard />
                                <UrlCard />
                                <UrlCard />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;