import { useRecoilState, useRecoilValue } from "recoil";
import {addNewSeletedState} from '../state/addNewSelectedState'
import AddArtist from "../forms/AddArtist";
import AddArt from "../forms/AddArt";
import {Publisher} from '../entities/constant'
import { ChangeEvent, useState } from "react";
import AddMovie from "../forms/AddMovie";
import AddJob from "../forms/AddJob";
import AddReview from "../forms/AddReview";
import AddArticle from "../forms/AddArticle";
import AddCarouselImage from "../forms/AddCarouselImage";
import { isAdmin } from "../state/userState";
import UnAuthorized from "../cards/UnAuthorized";

const AddNewRoute = () => {
    const [addSelected, setAddSelected] = useState<string>();
    const [isAdminTrue, setAdminTrue] = useRecoilState(isAdmin);

    const addSelectedValue = async(event: ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();
        setAddSelected(event.target.value);
    }

    return(
        <div>
            <div className="grid grid-rows-1"> 
                {
                    (isAdminTrue) &&
                    <div className="w-80 grid place-self-center relative">
                        <select className="block appearance-none w-full bg-gray-200 border 
                                border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded 
                                leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                id="grid-state" onChange={(e)=>addSelectedValue(e)}>

                            {
                                Publisher.map((add)=>(
                                    <option key={add} >{add}</option>
                                ))
                            }
                            
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                    </div>
                }
                {
                    (!isAdminTrue) &&
                    <div className="text-center">
                        <UnAuthorized />
                    </div>
                }
                
            </div>
            
            <div className="grid grid-rows-1 mt-2">
                <div className="bg-indigo-900 text-center py-4 lg:px-4">
                    <div className="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
                        <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">New</span>
                        <span className="font-semibold mr-2 text-center flex-auto">(Don't refresh page. Save or Submit your work.)</span>
                    </div>
                </div>
            </div>


            {
               (addSelected === 'Add Artist') && <AddArtist />
            }
            {
                (addSelected === 'Add Art') && <AddArt />
            }
            {
                (addSelected === 'Add Movie') && <AddMovie />
            }
            {
                (addSelected === 'Add Review') && <AddReview />
            }
            {
                (addSelected === 'Add Job') && <AddJob />
            }
            {
                (addSelected === 'Add Article') && <AddArticle />
            }
            {
                (addSelected === 'Add Carousel Image') && <AddCarouselImage />
            }
        </div>
    )
}

export default AddNewRoute;