import RatingCard from "../cards/RatingCard";
import {useRecoilState} from "recoil";
import {reviewsState} from "../state/recoilBootstrapState";

const ReviewComponent = () => {

    //const reviewsList = useRecoilValue(allReviewsState);
    const [reviewsList, setReviewsList] = useRecoilState(reviewsState);

    return(
        <div>
            {
                reviewsList?.map((review) => (
                    <RatingCard key={review.reviewid} review={review} />
                ))
            }
        </div>
    )
}

export default ReviewComponent ;