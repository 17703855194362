import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import NewsComponent from "../components/NewsComponent";
import IArticleMetaData from '../entities/IArticleMetaData';
import {useRecoilState} from "recoil";
import {articlesState} from "../state/recoilBootstrapState";
import MetaData from './MetaData'

const News = () => {

    const {id} = useParams();

    const [articlesList, setArticlesList] = useRecoilState(articlesState);
    const [currentArticle, setCurrentArticle] = useState<IArticleMetaData>();

    let headlines;
    let img;

    if(currentArticle !== undefined)
    {
        headlines = currentArticle!.headlines.length > 200 ? (currentArticle!.headlines.slice(0, 200) + "...") : currentArticle!.headlines ; 
        img = "https://surfserver.in" + currentArticle!.img;      
    }

    useEffect(() => {
        //setAsync(id!);
        const setAsync = async(id:string) => {
            const articles = articlesList.filter((item) => item.id === id)
            setCurrentArticle(articles[0])
        }
        setAsync(id!);
    },[id,articlesList]);// Ignore warning
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    });
    return(
        <div>
            {
               (currentArticle) && (
                    <MetaData title={headlines} img={img} type="article"
                                headline={currentArticle.body} tags={currentArticle.tags}
                                publishedtime = "Some time" />
               ) 
            }
            
            <div className="flex flex-row bg-gray-200">
                <div className="basis-1/5 flex justify-end m-2">
                    <div className="bg-white">
                        <NewsComponent type="sports"/>
                    </div>
                </div>
                <div className="basis-3/5 flex flex-col justify-center m-2 bg-white p-3">
                    {
                        (currentArticle) && (
                            <div>
                                <div className="flex mb-3 drop-shadow-xl justify-center ">
                                    <img src={img}
                                            alt="aji" className="object-contain rounded-xl" />
                                </div>
                                
                                <div className="relative bg-amber-900 rounded-xl p-3 text-center text-white shadow-lg shadow-cyan-500/50">
                                    <article>
                                        <h2 className="text-xl">{headlines}</h2>
                                        <br></br>
                                        <p className="text-base p-3 whitespace-pre-wrap">
                                            {currentArticle.body}
                                        </p>
                                    </article>
                                    <hr></hr>
                                    <br></br>
                                    <p className="absolute bottom-1 left-1">
                                        Brief by <span className="text-orange-300">{currentArticle?.publisher} </span>
                                    </p>
                                    <p className="absolute bottom-1 right-1 ">
                                        <a href={currentArticle.href} rel="noreferrer" target="_blank" > &copy; {currentArticle?.source} </a>
                                    </p>
                                </div>
                                <div>
                                    <NewsComponent type="news"/>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default News;