import IArticleMetaData from "../entities/IArticleMetaData"
import { v4 as uuid } from 'uuid';
import React, {useState } from 'react';
import SuccessCard from '../cards/SuccessCard';
import {uploadArticle, uploadFile} from '../client/apiCall';
import InfoCard from '../cards/InfoCard';
import { imagePath } from '../entities/constant';
import {isNullOrEmpty} from "../validations/validate";
import {TiTickOutline} from 'react-icons/ti';
import {useRecoilValue} from "recoil";
import {allArticlesState} from "../state/recoilBootstrapState";
import {ArticleStatus, ArticleType, ArticleSubType} from "../entities/constant"

const AddArticle = () => {

    const [uploadClicked, setUploadClicked] = useState(false);
    const [articleUploadedSuccessfully, SetArticleUploadedSuccessfully] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const [articleDetails, setArticleDetails] = useState<IArticleMetaData>({
                                                                id:uuid(),
                                                                type: ArticleType[0],
                                                                subtype: ArticleSubType[0],
                                                                headlines:'',
                                                                href:'',
                                                                body:'',
                                                                img:'',
                                                                tags:'',
                                                                source:'',
                                                                publisher:'admin'
                                                            });
    const [uploadImage, setUploadImage] = useState<File>();
    
    const updateArticleDetails= (event: React.FormEvent<HTMLInputElement>): void => {
        setArticleDetails({
            ...articleDetails,
            [event.currentTarget.name]: event.currentTarget.value
        })
    }

    const updateArticleBody= (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setArticleDetails({
            ...articleDetails,
            [event.currentTarget.name]: event.currentTarget.value
        })
    }

    const onSelectfromDropDown = (event: React.ChangeEvent<HTMLSelectElement>) => {
        event.preventDefault();

        setArticleDetails({
            ...articleDetails,
            [event.currentTarget.name]: event.currentTarget.value
        })
    }

    const setImgPath = (setImgPath) => {

        const val = imagePath + "/" +articleDetails.id + "." + setImgPath;
        setArticleDetails({
            ...articleDetails,
            "img": val
        })

    }

    const handleImageFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (!event.currentTarget.files) return;
        const file: FileList = event.currentTarget.files;
        setUploadImage(file[0]);
        const size = file[0].name.split('.'); // returns png/jpeg
        const format = file[0].name.split('.')[size.length-1];
        setImgPath(format);
    }

    let formData = new FormData();
    const onClickUpload = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        formData.append("name", articleDetails.id);
        formData.append("file",uploadImage!);

        const res = await uploadFile(formData);
        if(res) setUploadSuccess(true)

        setUploadClicked(true);
    }

    const handleOnSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(isNullOrEmpty(articleDetails.headlines))
        {
            let success = await uploadArticle(articleDetails);
            if(success) SetArticleUploadedSuccessfully(true);
        }
    }

    // Get All Ready and un published articles
    // This is temp code
    const articlesList = useRecoilValue(allArticlesState);
    console.log("In Add Articles : ", articlesList);
                                                            
    return(
        <div className="Article mt-4 bg-gray-200">
            <div className=" flex items-center justify-center">
                {/* <div className="basis-1/3 p-3 space-y-2">
                    {
                        articlesList?.map((item) => (
                            <div className="flex flex-col flex-wrap w-5/6 bg-white p-2">
                                Status: {ArticleStatus.Ready}
                                <div>
                                    <a href={item.href} rel="noreferrer" className="text-amber-700" 
                                        target="_blank ">{item.href}</a>
                                </div>
                                <div>
                                    Source: {item.source}
                                </div>
                            </div>
                            
                        ))  
                    }
                </div> */}
                <div>
                {
                    (!articleUploadedSuccessfully) &&
                        <form onSubmit={ e => handleOnSubmit(e) }>
                            <div className="grid grid-flow-row gap-2 place-items-center font-serif 
                                                bg-gradient-to-r from-pink-500 to-yellow-500
                                            text-gray-700 p-2">
                                <div className="font-bold text-xl mt-5">
                                    Article Id
                                </div>
                                <div>
                                    <input disabled className="h-8 w-80 rounded-md mr-3 p-2 text-black" name='id' 
                                            type="text" value={articleDetails.id}/>
                                </div>   

                                <div className="font-bold text-xl mt-5">
                                    Article Photo <InfoCard information="(make sure it is horizontal)" />
                                </div>
                                <div className="">
                                    <input type="file" className="w-60 rounded-md bg-white border-solid 
                                            border-4 border-yellow-500 " name="file" onChange = {handleImageFile} />
                                    {
                                        (uploadClicked && uploadSuccess) && 
                                            <span>
                                                <button className=" p-1 ml-3 pl-3 pr-3  
                                                    bg-yellow-900 rounded-md text-white" >
                                                                <TiTickOutline />  
                                                </button>
                                                {/* <button>
                                                    <MdCancelPresentation className=''/>
                                                </button> */}
                                            </span>
                                        
                                    }
                                    {
                                        (!uploadClicked) && 
                                            <span>
                                                <button className="p-1 ml-3 pl-3 pr-3  
                                                    bg-yellow-900 rounded-md text-white" onClick={onClickUpload}>
                                                            Upload
                                                </button>
                                            </span>
                                    }
                                </div>
                                {/**
                                 * ****************************************
                                 */}
                                <div className="font-bold text-xl mt-5">
                                    Article Type
                                </div>
                                <div className="h-10 w-80 grid place-self-center relative">
                                    <select className="block appearance-none w-full bg-gray-200 border 
                                            border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded 
                                            leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                            name="type" onChange={(e) => onSelectfromDropDown(e)} >
                                            <option disabled>Select</option>
                                        {
                                            ArticleType?.map((item:any)=>{
                                                return <option key={item} value={item} >{item} </option>
                                            })
                                        }
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg className="fill-current h-8 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                    </div>
                                </div>
                                {/**
                                 * ****************************************
                                 */}
                                <div className="font-bold text-xl mt-5">
                                    Article Sub-Type
                                </div>
                                <div className="h-10 w-80 grid place-self-center relative">
                                    <select className="block appearance-none w-full bg-gray-200 border 
                                            border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded 
                                            leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                            name="subtype" onChange={(e) => onSelectfromDropDown(e)} >
                                            <option disabled>Select</option>
                                        {
                                            ArticleSubType?.map((item:any)=>{
                                                return <option key={item} value={item} >{item} </option>
                                            })
                                        }
                                    </select>
                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                        <svg className="fill-current h-8 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                    </div>
                                </div>

                                {/**
                                 * ****************************************
                                 */}
                                <div className="font-bold text-xl mt-5">
                                    Article Headlines
                                </div>
                                <div>
                                    <textarea className="w-80 h-24 min-w-full max-w-2xl rounded-md mr-3 p-2" 
                                            placeholder="Article Headlines ..." name='headlines' onChange={e => updateArticleBody(e)}>
                                    </textarea>
                                </div>
                                {/**
                                 * ****************************************
                                 */}
                                <div className="font-bold text-xl mt-5">
                                    Article Body
                                </div>
                                <div>
                                    <textarea className="w-96 h-96 min-w-full max-w-2xl rounded-md mr-3 p-2" 
                                            placeholder="Article Body..." name='body' onChange={e => updateArticleBody(e)}>
                                    </textarea>
                                </div>
                                {/**
                                 * ****************************************
                                 */}
                                <div className="font-bold text-xl mt-5">
                                    Link <InfoCard information="(source link from where the article is copies)" /> 
                                </div>
                                <div>
                                    <input className="h-8 w-80 rounded-md mr-3 p-2 text-black place-self-center" 
                                        type="text" name='href'  onChange={e => updateArticleDetails(e)} placeholder="Link"/>
                                </div>
                                {/**
                                 * ****************************************
                                 */}
                                <div className="font-bold text-xl mt-5">
                                    Tags <InfoCard information="(Article relevant to eg: RRR, Ram Charan, NTR)" /> 
                                </div>
                                <div>
                                    <input className="h-8 w-80 rounded-md mr-3 p-2 text-black place-self-center" 
                                        type="text" name='tags'  onChange={e => updateArticleDetails(e)} placeholder="Tags"/>
                                </div>
                                {/**
                                 * ****************************************
                                 */}
                                <div className="font-bold text-xl mt-5">
                                    Source <InfoCard information="(Source website/company name)" /> 
                                </div>
                                <div>
                                    <input className="h-8 w-80 rounded-md mr-3 p-2 text-black place-self-center" 
                                        type="text" name='source'  onChange={e => updateArticleDetails(e)} placeholder="Source"/>
                                </div>
                                {/**
                                 * ****************************************
                                 */}
                                <div className="font-bold text-xl mt-5">
                                    Publisher <InfoCard information="(If the article is self written, provide author name)" /> 
                                </div>
                                <div>
                                    <input className="h-8 w-80 rounded-md mr-3 p-2 text-black place-self-center" 
                                        type="text" name='publisher'  onChange={e => updateArticleDetails(e)} placeholder="Publisher"/>
                                </div>

                                {/**
                                 * ****************************************
                                 */}
                                {
                                (!uploadClicked) && 
                                    <div>
                                        <div className="grid grid-rows-1 mt-2">
                                            <div className="bg-red-900 text-center py-2 lg:px-4 animate-pulse rounded-lg">
                                                <div className="p-2 bg-red-800 items-center text-indigo-100 leading-none 
                                                        lg:rounded-full flex lg:inline-flex" role="alert">
                                                    <span className="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">New</span>
                                                    <span className="font-semibold mr-2 text-center flex-auto">
                                                        (Please click on upload button in the top of the page to upload image)</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/**
                                 * ****************************************
                                 */}

                                <div className="text-slate-100 mb-10 mt-5">
                                    <button className="p-1 pl-3 pr-3  bg-yellow-900 rounded-md mr-10">Cancel</button>
                                    {
                                        uploadClicked && 
                                        <button type="submit" className="p-1 pl-3 pr-3  bg-yellow-900 rounded-md">Submit</button>
                                    }
                                    {
                                        (!uploadClicked) && 
                                        <button type="submit" className="p-1 pl-3 pr-3 bg-red-500 rounded-md" disabled>Submit</button>
                                    }
                                </div>
                            </div>
                        </form>
                    }
                </div>
            </div>
            
            {
                (articleUploadedSuccessfully) && 
                        <div>
                            <SuccessCard />
                        </div>
            }
        </div>
    )
}

export default AddArticle;