import {useRecoilValue} from "recoil";
import ICarouselImages from "../entities/ICarouselImages";
import {allCarouselState} from "../state/recoilBootstrapState";
import { useState } from "react";
import {deleteCarousel, deleteImage, updateCarousel} from '../client/apiCall'

const EditCarousels = () => {
    const [currentItem, setCurrentItem] = useState<ICarouselImages>({
                                                                    id:'',
                                                                    moviename:'',
                                                                    img:'',
                                                                    url:''
                                                                });
                                                                
    const carouselList = useRecoilValue(allCarouselState)!;
    const [editClicked, setEditClicked] = useState(false);                                                            
    const higherThanMaxLength = carouselList?.length! + 1;
    const [index, setIndex] = useState<number>(higherThanMaxLength);

    const onEdit = (item:ICarouselImages,i:number) =>{
        console.log("number : ", i);
        setIndex(i);
        setEditClicked(true);
        setCurrentItem(item);
    }

    const updateState= (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setCurrentItem({
            ...currentItem,
            [event.currentTarget.name]: event.currentTarget.value
        })
    }
    const onSave = async (item:ICarouselImages, i) => {
        setIndex(higherThanMaxLength);
        setEditClicked(false);
        const success = await updateCarousel(currentItem)
        if(success){
            alert('Succesfully updated')
        }
    }
    const onDelete = async (item:ICarouselImages, i) => {
        alert("You are about to delete: "+ item.id)
        const success = await deleteCarousel(item)
        if(success){
            deleteImage(item.img);
            alert('Succesfully updated')
        }
    }

    return(
        <div className="grid grid-rows-1 place-items-center">
            <div className="grid  divide-y">
                {
                    carouselList.map((item: ICarouselImages, i) => (
                        <div className="grid grid-flow-col gap-1 " key={item.id} >
                             
                            <div className="w-64 border-2 border-sky-500 p-3">
                               Id: {item.id}
                            </div>
                               
                            {
                                (index === i) && (
                                    <textarea className="w-64 border-2 border-sky-500 p-3" name="moviename"
                                      placeholder="Movie Name"  onChange={e => updateState(e)}>
                                        {item.moviename}
                                    </textarea>
                                )
                            }
                            {
                                (index !== i) && (
                                    <div className="w-64 border-2 border-sky-500 p-3">
                                     Movie Name:   {item.moviename}
                                    </div>
                                ) 
                            }
                            {
                                (index === i) && (
                                    <textarea className="w-64 border-2 border-sky-500 p-3" name="url" 
                                    placeholder="Movie url"  onChange={e => updateState(e)}>
                                        {item.url}
                                    </textarea>
                                )
                            }
                            {
                                (index !== i) && (
                                    <div className="w-64 border-2 border-sky-500 p-3 truncate">
                                      URL:  {item.url}
                                    </div>
                                ) 
                            }
                            
                            {/* {
                                (index === i) && (
                                    <textarea className="w-64 border-2 border-sky-500 p-3" name="img" 
                                    placeholder="Movie img"  onChange={e => updateState(e)}>
                                       {item.img}
                                    </textarea>
                                )
                            }
                            {
                                (index !== i) && ( */}
                                    <div className="w-64 border-2 border-sky-500 p-3">
                                        {item.img}
                                    </div>
                                {/* ) 
                            } */}
                            
                            
                            <button className="bg-amber-700 p-3 rounded-xl m-6"
                                onClick={() => onEdit(item, i)} >
                                Edit
                            </button>
                            <button className="bg-amber-700 p-3 rounded-xl m-6"
                                onClick={() => onSave(item, i)} >
                                Save
                            </button>
                            <button className="bg-amber-700 p-3 rounded-xl m-6"
                                onClick={() => onDelete(item, i)} >
                                Delete
                            </button>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default EditCarousels;