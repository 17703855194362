import {Helmet} from "react-helmet";
import {useLocation} from 'react-router-dom'

interface Props{
    title:string,
    img:string,
    type:string,
    headline:string,
    tags:string,
    publishedtime:string
}

const MetaData = ({title, img, type,headline,tags,publishedtime}:Props) => {

    const url = window.location.href;
    const body = (headline.length >= 50) ? headline.slice(0,49) : headline;

    return(
        <div>
            <Helmet>
                <title>{title}</title>
                <meta name="keywords" content={tags} />
                <meta name="news_keywords" content={tags} />
                <meta name="description" content={body} />
                <meta property="og:type" content={type} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={body} />
                <meta name="og:image" content={img} />
                <meta property="og:image:width" content="900" />
                <meta property="og:image:height" content="500" />
                <meta property="og:image:alt" content={title} />
                <meta property="og:site_name" content="SHOWbuoy" />
                <meta name="twitter:url" content={url} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={body} />
                <meta name="twitter:image" content={img} />
                <meta name="twitter:image:alt" content={title} />
                <meta property="article:published_time" content={publishedtime} />
                <meta property="article:modified_time" content={publishedtime} />
                <meta itemProp="name" content={title} />
                <meta itemProp="description" content={body} />
                <meta itemProp="image" content={img}></meta>
                {/* <meta property="fb:pages" content="111445058579" />
                <meta property="fb:app_id" content="387606842082586" />
                <meta property="fb:admins" content="100006810078735" /> */}
            </Helmet>
        </div>
    )
}

export default MetaData;