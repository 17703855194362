import OttCard from '../cards/OttCard';
import logo from '../logo/SHOWbuoy_logo.png'
import MetaData from './MetaData'
import { useEffect, useState } from 'react';
import { getTopMoviesByPlatform } from '../client/getData';
import IMovie from '../entities/IMovie';
import Search from '../components/Search';

const MobileMovies = () => {
    const [topmovies, SetTopmovies] = useState<Array<IMovie>>([])
    const [netflix, SetNetflix] = useState<Array<IMovie>>([])
    const [prime, SetPrime] = useState<Array<IMovie>>([])
    const [zee5, SetZee5] = useState<Array<IMovie>>([])
    const [disneyhotstar, SetDisneyhotstar] = useState<Array<IMovie>>([])
    const [aha, SetAha] = useState<Array<IMovie>>([])
    const [sunnxt, SetSunnxt] = useState<Array<IMovie>>([])

    const limit = 8;
    const ottlimit = 10
    const getData = async(key:string) => {
        if(key === "All"){
            let data = await getTopMoviesByPlatform(key,limit);
            SetTopmovies(data!);
        }
        if(key === "NetFlix"){
            let data = await getTopMoviesByPlatform(key,ottlimit);
            SetNetflix(data!);
        }
        if(key === "Prime Video"){
            let data = await getTopMoviesByPlatform(key,ottlimit);
            SetPrime(data!);
        }
        if(key === "Zee5"){
            let data = await getTopMoviesByPlatform(key,ottlimit);
            SetZee5(data!);
        }
        if(key === "Disney + Hotstar"){
            let data = await getTopMoviesByPlatform(key,ottlimit);
            SetDisneyhotstar(data!);
        }
        if(key === "Aha"){
            let data = await getTopMoviesByPlatform(key,ottlimit);
            SetAha(data!);
        }
        // if(key === "Sun NXT"){
        //     let data = await getTopMoviesByPlatform(key,ottlimit);
        //     SetSunnxt(data!);
        // }
        return data;
    }

    const data = [
        // {
        //     key:"All",
        //     value: topmovies,
        //     ref:"All"
        // },
        {
            key:"NetFlix",
            value: netflix,
            ref:"NetFlix"
        },
        {
            key:"Prime Video",
            value: prime,
            ref:"Prime-Video"
        },
        {
            key:"Zee5",
            value: zee5,
            ref:"Zee5"
        },
        {
            key:"Disney + Hotstar",
            value: disneyhotstar,
            ref:"Disney+Hotstar"
        },
        {
            key:"Aha",
            value: aha,
            ref:"Aha"
        }
        // ,
        // {
        //     key:"Sun NXT",
        //     value: sunnxt,
        //     ref:"Sun-NXT"
        // }
    ]

    useEffect(()=>{
        data.forEach((item)=>{
            getData(item.key);
        })
    },[])

    return(
        <div className="MobileMovies td:hidden">
            <MetaData title="SHOWbuoy | Latest Movies" img={logo} type="page"
                        headline="Find all the latest Movies in Theaters and OTT " 
                        tags="movies, telugu movies, hindi movies, tamil movies, kannada movies, 
                                malayalam movies, top movies, Netflix, Prime Video, Sun Nxt, Aha Video,
                                Top Indian movies in Netflix, Disney + Hotstar, Youtube, Jio Movies, Zee 5,
                                Top Telugu Movies, Top Hindi Movies, Top Tamil Movies, Top Malayalam Movies"
                            publishedtime = "Some time" 
            />
            <br></br>
            <div className='text-center grid justify-items-center'>
                    <Search />
            </div>
            {/* <div className="mt-3">
                <div className="m-3">
                        Top 10 Movies
                        <button className='ml-5 animate-bounce'> 
                            <CgChevronDoubleDownR />   
                        </button>
                </div>
                <div className="grid grid-flow-col auto-cols-max overflow-x-auto gap-3 p-2">
                        {
                            topMovies.filter((movie,i) => i<maxItems ).map((movie)=>
                                <div key={movie.id} className='grid place-self-center'>
                                    <MovieCard movie={movie} keyVal={movie.id} key={movie.id}/>
                                </div>
                            )
                        }
                </div>
            </div>

            <div className="mt-3">
                <div className="m-3">
                    Top 10 Tv Shows
                    <button className='ml-5 animate-bounce'> 
                        <CgChevronDoubleDownR />   
                    </button>
                </div>
                <div className="grid grid-flow-col auto-cols-max overflow-x-auto gap-3 p-2">
                    {
                        topMovies.filter((movie,i) => i<maxItems ).map((movie)=>
                            <div key={movie.id} className='grid place-self-center'>
                                <MovieCard movie={movie} keyVal={movie.id} key={movie.id}/>
                            </div>
                        )
                    }
                </div>
            </div> */}
                    
            <div className="grid grid-flow-row mt-10 p-2 text-black">
            <hr></hr>
                <h1 className='text-xl text-green-700 text-center'>This week releases in OTT</h1>
                <hr></hr>
                {
                    data.map((element, i) => (
                        <OttCard key={element.key} ItemKey={element.key} ItemValue={element.value} Reference={element.ref} />
                    ))
                }
                
            </div>
        </div>
    )
}

export default MobileMovies;