import IArticleMetaData from "../entities/IArticleMetaData";

interface Props {
    data:IArticleMetaData,
    type:string
}

const NewsCard = ({data,type} : Props) => {
    const headlines = data.headlines.length > 200 ? (data.headlines.slice(0, 200) + "...") : data.headlines ;    
   const imgVal = "https://surfserver.in" + data.img;     
   let url = "/news/"+ headlines+"/"+data.id;
    url = url.replace(/\s/g, '-')

    return(
        <div className="m:max-w-sm hover:shadow-lg hover:shadow-cyan-500/50 hover:rounded-xl">
             <a href={url} rel="noreferrer"> 
                {
                     (type === "news") && 

                     <div className="grid grid-flow-row h-full td:h-64 m:h-48 bg-cyan-700 rounded-xl 
                                shadow-xl hover:bg-green-400 td:w-64 m:w-40 max-w-sm place-self-center">
                         <div className="place-self-center">
                            <img src={imgVal} 
                                            alt="Not Available" className="object-contain  td:h-40 m:h-24 rounded-xl" />
                        </div>

                        <hr></hr>

                        <div className="p-2 text-slate-300 td:text-sm m:text-xs">
                            <p className="text-center inline-block p-1 pb-3 text-white h-16 overflow-hidden">
                                    {headlines}
                                </p>   
                        </div>
                    </div>
                }
                {
                    (type === "sports") && 
                    <div className="td:w-48 td:h-64 m:w-40 m:h-48 bg-cyan-700 rounded-xl shadow-xl hover:bg-green-400">
                        <div className="flex justify-center relative ">
                            <img src={imgVal} 
                                        alt="Not Available" className="object-contain td:w-48 td:h-40 m:h-24 rounded-xl" />
                        </div>
                        <hr></hr>
                        <div>
                            <p className="text-sm inline-block m-3 text-white h-16 overflow-hidden">
                                {headlines}
                            </p>   
                        </div>
                    </div>
                } 
            </a>
        </div>
    )
}

export default NewsCard