

const SuccessCard = () => {

    const reload = () => {
        window.location.reload();
    }

    return(
        <div className="SuccessCard">
            <div className="grid grid-flow-row">
                
                <div className="grid w-full h-60 place-items-center p-4 border-4 
                                bg-lime-800 text-center text-white">
                    Success
                </div>
                <div className="grid place-items-center p-4 border-4 
                                     text-center text-white rounded-xl">
                    <button className="p-4 border-4 bg-lime-800
                                     text-center text-white rounded-xl"
                            onClick={reload}>
                        Add Another
                    </button>
                </div>
            </div>
        </div> 
    )
}

export default SuccessCard;