import { useEffect, useState } from "react";
import IMovie from "../entities/IMovie";

interface Props {
    movie: IMovie,
    keyVal: string,
}

const MovieCard = ({movie, keyVal} : Props) => {

    let imgUrl = "https://surfserver.in"+movie.img
    
    const [isReleased, setIsReleased] = useState(false);

    useEffect(() => {
        if(movie.ottreleasedate != null)
        {
            if(new Date().getTime() >= new Date(movie.ottreleasedate).getTime()){
                setIsReleased(true);
            }else{
                setIsReleased(false);
            }
        }
    },[movie.ottreleasedate,setIsReleased])

    return(
        <div className="MovieCard">
            <div className="grid grid-flow-row w-40 h-64 relative">
                <div className="left-0 top-0 text-white bg-orange-900 rounded-l pl-2 translate-y-1">
                    {
                        (isReleased) ? <p> Available</p> 
                                    : <p> Coming Soon </p>   
                    }
                    
                </div>
                <div className="grid rounded min-h-full justify-items-center">
                    
                    <img className="object-cover h-56 w-full shadow-lg shadow-orange-700 rounded " 
                                    src={imgUrl} alt=""/>
                    
                </div>
                <div className="grid content-center text-center text-white bg-orange-900 rounded-l mt-1">
                    {movie.name}
                </div>
            </div>
        </div>
    )
}

export default MovieCard;