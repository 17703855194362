import axios from 'axios';
import IArtist from '../entities/IArtist';
import ICarouselImages from '../entities/ICarouselImages';
import { Iipl } from '../entities/Iipl';
import {IMovieList, IArtistList, IArticleList} from '../entities/IList';
import IMovie from '../entities/IMovie';
import IMovieReview from '../entities/IMovieReview';
import IArticleMetaData from '../entities/IArticleMetaData'

let headers = {
    "Access-Control-Allow-Origin": "*",
    'Content-Type' : 'application/json'
}

// axios.defaults.baseURL = 'http://localhost:8080';
 axios.defaults.baseURL = 'https://surfserver.in';

const getMovieById = async (id) => {
    let movie: IMovie;
    try {
      const response = await axios.get(`/movie/${id}`,{headers});
      movie = response.data.rows[0];
      return movie;
    } catch (error) {
      console.error(error);
    }
}

const getAllMoviesMetaData = async () => {
    //let movies: Array<IMovieList> = [];
    try {
      const res = await axios.get('/movies/meta');
      let movies: Array<IMovieList> = res.data.rows
      return movies;
    } catch (error) {
      console.error(error);
    }
}

const getTopMovies = async () => {
  //let movies: Array<IMovieList> = [];
  try {
    const res = await axios.get('/movies/top');
    let movies: Array<IMovie> = res.data.rows
    return movies;
  } catch (error) {
    console.error(error);
  }
}

///movies/latest/:platform/:limit
const getTopMoviesByPlatform = async (platform, limit) => {
  //let movies: Array<IMovieList> = [];
  try {
    const res = await axios.get(`/movies/latest/${platform}/${limit}`);
    let movies: Array<IMovie> = res.data.rows
    return movies;
  } catch (error) {
    console.error(error);
  }
}


const getAllArtistsMetaData = async () => {
    let artists: Array<IArtistList> = [];
    try {
      const response = await axios.get('/artists/meta', {headers});
      artists = response.data.rows;
      return artists;
    } catch (error) {
      console.error(error);
    }
}

const getAllArtists = async () => {
  let artists: Array<IArtist> = [];
  try {
    const response = await axios.get('/artists/list', {headers});
    artists = response.data.rows;
    return artists;
  } catch (error) {
    console.error(error);
  }
}

const getAllArticles = async () => {
  let articles: Array<IArticleMetaData> = [];
  try {
    const response = await axios.get('/articles/list', {headers});
    articles = response.data.rows;
    return articles;
  } catch (error) {
    console.error(error);
  }
}

const getCarouselImages = async () => {
  let carouselImgs: Array<ICarouselImages> = [];
  try {
    const response = await axios.get('/carousel/list', {headers});
    carouselImgs = response.data.rows;
    return carouselImgs;
  } catch (error) {
    console.error(error);
  }
}

const getReviewsData = async () => {
  let movieReviews: Array<IMovieReview> = [];
  try {
    const response = await axios.get('/reviews/latest', {headers});
    movieReviews = response.data.rows;
    return movieReviews;
  } catch (error) {
    console.error(error);
  }
}

const getReviewDatabyId= async (id:string) => {
  let movieReviews: Array<IMovieReview> = [];
  try {
    const response = await axios.get(`/review/${id}`, {headers});
    movieReviews = response.data.rows;
    return movieReviews[0];// We will get only one roe in a list
  } catch (error) {
    console.error(error);
  }
}

const getArtistbyId= async (id:string) => {
  let artist: Array<IArtist> = [];
  try {
    const response = await axios.get(`/artist/${id}`, {headers});
    artist = response.data.rows;
    return artist[0];// We will get only one roe in a list
  } catch (error) {
    console.error(error);
  }
}

const getArticlebyId= async (id:string) => {
  let article: Array<IArticleList>;
  try {
    const response = await axios.get(`/article/${id}`, {headers});
    article = response.data.rows;
    return article[0];// We will get only one roe in a list
  } catch (error) {
    console.error(error);
  }
}

const getSportsListbyGroup= async (group:string) => {
  let ipl: Array<Iipl> = [];
  try {
    const response = await axios.get(`/sports/list/${group}`, {headers});
    ipl = response.data.rows;
    return ipl;// We will get only one roe in a list
  } catch (error) {
    console.error(error);
  }
}

export{
    getMovieById,
    getAllMoviesMetaData,
    getTopMovies,
    getTopMoviesByPlatform,
    getAllArtistsMetaData,
    getAllArtists,
    getAllArticles,
    getCarouselImages,
    getReviewsData,
    getReviewDatabyId,
    getArtistbyId,
    getArticlebyId,
    getSportsListbyGroup
}