
interface Props{
    rating: number,
}

const Rating = ({rating}:Props) => {
    let filledStars: number = (!isNaN(rating)) ? Math.floor(rating) : 0 ;
    let unFilledStars: number = 5-filledStars;
    let colorpercent = (rating - filledStars) * 100;
    // console.log(unFilledStars, colorpercent)
    // let uncolorPercent = 100 - colorpercent;
    // colorpercent = colorpercent + "%";
    // uncolorPercent = uncolorPercent +"%";
   

    let filledItems:React.SVGProps<SVGElement>[]= [];
    
    const setStars = () => {
        for (let i=0;i< filledStars;i++)
        {
            filledItems.push(
                <svg className="w-5 h-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
            )
        }
        if(colorpercent !== 0)
        {
            filledItems.push(
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" className="w-5 h-5 text-gray-300 dark:text-gray-500" fill="currentColor">
                    <defs>
                        <linearGradient id="half_grad">
                            <stop offset="40%" stopColor="yellow"/>
                            <stop offset="60%" stopColor="#6B7280" stopOpacity={1}/>
                        </linearGradient>
                    </defs>
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
                        fill="url(#half_grad)"/>
                </svg>
            )
            unFilledStars = unFilledStars - 1; // If a half star is given then it will decrease a start from unfilled
        }
        for (let i=0;i<unFilledStars;i++)
        {
            filledItems.push(
                <svg className="w-5 h-5 text-gray-300 dark:text-gray-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>
            )
        }
    }
    setStars();
    return(
        <div className="flex justify-center h-12 w-full rounded text-white bg-gradient-to-r from-cyan-500 to-blue-500">
            
            <div className="flex items-center">
                <div>
                    <b className="text-yellow-200 text-xl ">SHOW </b> Rating : &nbsp;
                </div>
                {
                    filledItems.map((item,i) => (
                        <div key={i}>
                            {item}
                        </div>
                    ))
                }
                {
                    (rating === undefined || isNaN(rating)) && 
                        <p className="ml-2 font-medium text-white">Rating Not Available</p>
                }
                {
                    (!isNaN(rating)) && 
                        <p className="ml-2 font-medium text-white">{rating} out of 5</p>
                }
            </div>
        </div>
    )
}

export default Rating;