import CreateTaskCard from "./CreateTaskCard"
import ITask from "./ITask"

const data = [
    {
        task:"Task 1",
        assignedto: "Charu",
        status:"status"
    },
    {
        task:"Task 2",
        assignedto: "Srava",
        status:"status"
    }
]



const reporters = [
    "1",
    "2"
]
// person logs in
// Fetch Tasks Assigned to him
// Fetch reporters

const WorkflowCreateTasks = () => {
    return(
        <div>
            <div>
                <CreateTaskCard />
            </div>
            
        </div>
    )
}

export default WorkflowCreateTasks;