export{
    searchNames,
    searchMovies
}

async function searchNames(val:string, ListofData){
    let filteredData = [];
    let len = val.length;
    console.log("ListofData :", ListofData);
    filteredData = ListofData.filter((item:any) => {
                        console.log("item : ", item);
                        if(item.alias !== undefined && item.alias !== null){
                            return ((item.name.trim().length >= len || item.alias.trim().length >= len) 
                                        && (item.name.trim().slice(0,len).toLowerCase() === val.toLowerCase() || 
                                                item.alias.trim().slice(0,len).toLowerCase() === val.toLowerCase() ))
                        }else{
                            return(item.name.trim().length >= len && item.name.trim().slice(0,len).toLowerCase() === val.toLowerCase()) 
                        }
                    })
    return filteredData;
}

async function searchMovies(val:string, movieList){
    let filteredData = [];
    let len = val.length;
    filteredData = movieList?.filter((item) => {
                return((item.name.trim().length >= len) && (item.name.trim().slice(0,len).toLowerCase() === val.toLowerCase())) 
        })

    return filteredData;
}